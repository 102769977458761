import { Widget } from '@kyberswap/widgets'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { useIsDarkMode } from 'theme/components/ThemeToggle'

const PageWrapper = styled.div`
  max-width: 680px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 68px 8px 0px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

export default function Kyber() {
  const isDarkMode = useIsDarkMode()
  const { provider } = useWeb3React()

  const lightTheme = {
    text: '#222222',
    subText: '#5E5E5E',
    primary: '#FFFFFF',
    dialog: '#FBFBFB',
    secondary: '#F5F5F5',
    interactive: '#E2E2E2',
    stroke: '#505050',
    accent: '#28E0B9',
    success: '#189470',
    warning: '#FF9901',
    error: '#FF537B',
    fontFamily: 'Work Sans',
    borderRadius: '16px',
    buttonRadius: '999px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
  }

  const darkTheme = {
    text: '#FFFFFF',
    subText: '#A9A9A9',
    primary: '#1C1C1C',
    dialog: '#313131',
    secondary: '#0F0F0F',
    interactive: '#292929',
    stroke: '#505050',
    accent: '#28E0B9',
    success: '#189470',
    warning: '#FF9901',
    error: '#FF537B',
    fontFamily: 'Work Sans',
    borderRadius: '16px',
    buttonRadius: '999px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
  }

  const kyberTheme = isDarkMode ? darkTheme : lightTheme

  return (
    <PageWrapper>
      <Widget client="Kyber" tokenList={[]} provider={provider} theme={kyberTheme} title={<div>Kyberswap</div>} />
    </PageWrapper>
  )
}
