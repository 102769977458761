import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from 'few-sdk-core-multiple-network-2'
import { Text } from 'rebass'

import { ButtonPrimary } from '../../components/Button'
import CurrencyLogo from '../../components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from '../../components/Row'
import { Field } from '../../state/mint/meme/actions'
import { ThemedText } from '../../theme'

// eslint-disable-next-line import/no-unused-modules
export function ConfirmMemeLaunchPadModalBottom({
  isBuyToken,
  currencies,
  parsedAmounts,
  tokenSymbol,
  onDeploy,
}: {
  isBuyToken?: boolean
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  tokenSymbol?: string
  onDeploy: () => void
}) {
  return (
    <>
      <RowBetween>
        <ThemedText.DeprecatedBody>
          <Trans>
            {isBuyToken
              ? `Purchase ${tokenSymbol} tokens with ${currencies[Field.BUY_MEME_CURRENCY]?.symbol}`
              : `Launch ${tokenSymbol} token`}
          </Trans>
        </ThemedText.DeprecatedBody>
        {isBuyToken && (
          <RowFixed>
            <CurrencyLogo currency={currencies[Field.BUY_MEME_CURRENCY]} style={{ marginRight: '8px' }} />
            <ThemedText.DeprecatedBody>
              {parsedAmounts[Field.BUY_MEME_CURRENCY]?.toSignificant(6)}
            </ThemedText.DeprecatedBody>
          </RowFixed>
        )}
      </RowBetween>
      {/* <RowBetween>
        <ThemedText.DeprecatedBody>
          <Trans>{currencies[Field.CURRENCY_B]?.symbol} Deposited</Trans>
        </ThemedText.DeprecatedBody>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <ThemedText.DeprecatedBody>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</ThemedText.DeprecatedBody>
        </RowFixed>
      </RowBetween> */}
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onDeploy}>
        <Text fontWeight={535} fontSize={20}>
          {isBuyToken ? <Trans>Launch Token & Buy</Trans> : <Trans>Launch Token</Trans>}
        </Text>
      </ButtonPrimary>
    </>
  )
}
