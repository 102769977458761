import { useWeb3React } from '@web3-react/core'
import { AirdropUnsupported } from 'components/AirdropUnsupported'
import { POINT } from 'constants/tokens'
import { ChainId } from 'few-sdk-core-multiple-network-2'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useClaimTokenInfo } from '../../state/stake/hooks'
import AirdropManage from './AirdropManage'

function useNetworkSupportsAirdrop() {
  const { chainId } = useWeb3React()
  return (chainId && chainId == ChainId.BLAST) || (chainId && chainId == ChainId.SEPOLIA)
}

export default function Airdrop() {
  const { account, chainId } = useWeb3React()
  const networkSupportsAirdrop = useNetworkSupportsAirdrop()
  const theme = useTheme()
  const pointBalance = useCurrencyBalance(account ?? undefined, POINT[chainId ?? ChainId.BLAST])

  const infos = useClaimTokenInfo()

  const userTotalAirdropPoint = useMemo(() => {
    let amount = 0
    infos.forEach((i) => (amount += Number(i.airdropAmount.toExact())))
    return amount
  }, [infos])

  if (!networkSupportsAirdrop) return <AirdropUnsupported />

  return (
    <div className="w-full flex-1 max-w-full min-h-dvh">
      <div className="w-full flex flex-col">
        <div className="flex w-full h-auto justify-center">
          <div className="w-full h-full max-w-[2040px] flex justify-between px-5 sm:px-[72px] pb-10">
            <div className="blcok w-full lg:flex justify-between gap-[48px] relative flex-col lg:flex-row sm:items-end flex-wrap">
              <div className="flex-1 max-w-full md:max-w-[636px]">
                <div className="flex justify-between w-full mt-6">
                  <div className="flex justify-start items-center flex-1 max-w-full">
                    <div className="inline-flex font-mona max-w-full sm:max-w-[2040px] text-2xl sm:text-3xl font-extrabold w-full">
                      <span className="inline-block w-full line-clamp-1">Ring Airdrop</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 sm:gap-4">
                    <div>
                      <div className="font-bold text-sm text-white inline-flex items-center group hover:border-white active:opacity-70">
                        <Link style={{ color: theme.white, textDecoration: 'underline' }} to="/redeem">
                          <button
                            className="inline-flex items-center bg-[#4673f1] hover:bg-[#2d60f9] justify-center whitespace-nowrap font-bold transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none h-[44px] rounded-[8px] px-6 py-[11px] text-sm leading-[20px]"
                            type="button"
                          >
                            Redeem
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between w-full mt-3">
                  <div className="mt-3 text-sm line-clamp-2 break-words text-info-lighten2 ">
                    <span>
                      By following the official Ring Protocol on Twitter and Discord for announced campaigns, you can
                      earn Ring Points through airdrops and redeem them for Ring Tokens.
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-5 relative">
                <div className="inline-flex gap-4 w-full flex-wrap sm:flex-nowrap h-fit">
                  <ul
                    className={`inline-flex gap-5 sm:gap-8 py-5 px-6 ${
                      theme.darkMode ? 'bg-[#15171c] border-[#090a0d]' : 'bg-white border-[#22222212]'
                    } border-[1px] rounded-xl  max-w-fit`}
                  >
                    <li>
                      <span className="inline-flex items-center font-normal text-sm w-full">Campaigns</span>
                      <div className="text-xl font-extrabold mt-4 flex w-full justify-center">
                        <span className="font-extrabold text-xl">{infos.length}</span>
                      </div>
                    </li>
                    <div className={`shrink-0 ${theme.darkMode ? 'bg-[#1b1e24]' : 'bg-[#22222222]'} w-[1px]`}></div>
                    <li>
                      <div className="inline-flex items-center font-normal text-sm w-full">
                        <span className="flex">My Airdrop Point</span>
                      </div>
                      <div className="text-lg font-extrabold text-whtie mt-4 flex w-full justify-center">
                        {userTotalAirdropPoint.toFixed(2)}
                      </div>
                    </li>
                    {/* <div className={`shrink-0 ${theme.darkMode ? 'bg-[#1b1e24]' : 'bg-[#22222222]'} w-[1px]`}></div>
                    <li>
                      <div className="inline-flex items-center font-normal w-full">Backer</div>
                      <div className="text-xl font-extrabold mt-4 font-mona">--</div>
                    </li> */}
                  </ul>
                  <ul
                    className={`inline-flex gap-8 px-5 py-5 ${
                      theme.darkMode ? 'bg-[#15171c] border-[#090a0d]' : 'bg-white border-[#22222212]'
                    } border-[1px] rounded-xl max-w-fit`}
                  >
                    <li className="w-full sm:w-auto">
                      <div className="inline-flex items-center font-normal w-full">Point Balance</div>
                      <div className="text-xl font-extrabold text-whtie mt-4 flex">
                        <div className="flex w-full justify-center">
                          <span className="">{pointBalance ? pointBalance.toSignificant(2) : '0'}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex justify-center items-center">
        <div className="h-auto flex flex-col max-w-[2040px] w-[calc(100vw_-_40px)] sm:w-[calc(100vw_-_144px)]">
          <div className="w-full">
            <div className="w-full flex items-center justify-between gap-3 pb-6">
              <div
                className={`inline-flex items-center justify-between flex-1 border-b-[1px] ${
                  theme.darkMode ? 'border-[#20242b]' : 'border-[#22222212]'
                } bg-transparent`}
              >
                <div className="flex items-center gap-7 sm:gap-12">
                  <button
                    type="button"
                    className="inline-flex border-b-[#CCFD07] border-4 border-transparent items-center justify-center whitespace-nowrap transition-all font-normal text-info text-xl pb-3 px-6 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                  >
                    Airdrop 🔥
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex items-center border-4 border-transparent justify-center whitespace-nowrap transition-all font-normal text-info text-xl pb-3 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                  >
                    Expired
                  </button> */}
                </div>
              </div>
            </div>
            <div className="w-full transition-all box-border">
              <div className="overflow-auto space-grid-container">
                <div className="grid gap-4 sm:gap-7 xl:grid-cols-[repeat(3,minmax(264px,1fr))] lg:grid-cols-[repeat(2,minmax(264px,1fr))]">
                  {infos?.map((airdropTokenInfo, index) => {
                    return <AirdropManage key={index} airdropTokenInfo={airdropTokenInfo} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
