import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { ChainId, Currency, CurrencyAmount } from 'few-sdk-core-multiple-network-2'
import { useAllTokens } from 'hooks/Tokens'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import styled from 'styled-components'
import { EllipsisStyle, ThemedText } from 'theme/components'
import { formatNumber, NumberType } from 'utils/formatNumbers'

import { PortfolioLogo } from '../../../../components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import PortfolioRow, {
  PortfolioSkeleton,
  PortfolioTabWrapper,
} from '../../../../components/AccountDrawer/MiniPortfolio/PortfolioRow'

export default function Tokens({ account }: { account: string }) {
  const { chainId } = useWeb3React()
  const allTokens = useAllTokens()
  const native = useNativeCurrency(chainId)
  const currencies = useMemo(() => {
    if (allTokens && native) {
      return [native, ...Object.values(allTokens)]
    }
    return []
  }, [allTokens, native])
  const tokenBalances: CurrencyAmount<Currency>[] = useCurrencyBalances(
    account,
    currencies
  ) as CurrencyAmount<Currency>[]

  if (!tokenBalances.length || !tokenBalances) {
    return <PortfolioSkeleton />
  }

  return (
    <PortfolioTabWrapper>
      {tokenBalances.map(
        (tokenBalance: CurrencyAmount<Currency>) =>
          tokenBalance && (
            <TokenRow
              key={`${tokenBalance.currency.symbol} + ${chainId}`}
              currency={tokenBalance.currency}
              balance={tokenBalance}
            />
          )
      )}
    </PortfolioTabWrapper>
  )
}

const TokenBalanceText = styled(ThemedText.BodySecondary)`
  ${EllipsisStyle}
`
const TokenNameText = styled(ThemedText.SubHeader)`
  ${EllipsisStyle}
`

type PortfolioToken = NonNullable<Currency>

function TokenRow({ currency, balance }: { currency: Currency & PortfolioToken; balance: CurrencyAmount<Currency> }) {
  const { chainId } = useWeb3React()

  // TODO: click currency navigate to swap
  // const navigate = useNavigate()
  // const toggleWalletDrawer = useToggleAccountDrawer()

  // const navigateToTokenDetails = useCallback(async () => {
  //   navigate(getTokenDetailsURL({ ...token }))
  //   toggleWalletDrawer()
  // }, [navigate, toggleWalletDrawer])

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_TOKEN_ROW}
      properties={{ chain_id: chainId, token_name: currency?.name, address: currency.symbol }}
    >
      <PortfolioRow
        left={<PortfolioLogo chainId={chainId ?? ChainId.BLAST} currencies={[currency]} size="40px" />}
        title={<TokenNameText>{currency?.name}</TokenNameText>}
        descriptor={<TokenBalanceText>{currency?.symbol}</TokenBalanceText>}
        right={
          <>
            <ThemedText.SubHeader>
              {formatNumber(Number(balance.toSignificant(4)), NumberType.TokenNonTx)}
            </ThemedText.SubHeader>
          </>
        }
      />
    </TraceEvent>
  )
}
