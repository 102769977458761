import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import Column from 'components/Column'
import { ChainId, Token } from 'few-sdk-core-multiple-network-2'
import { ChainTokenMap, useAllTokensMultichain } from 'hooks/Tokens'
import _ from 'lodash'
import { useMemo } from 'react'
import { useConfirmedTransactions, usePendingTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/types'
import { useAllLiquidityToken } from 'state/user/hooks'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { EmptyWalletModule } from '../EmptyWalletContent'
import { ActivityRow } from './ActivityRow'
import { transactionToActivity } from './parseLocal'
import { Activity } from './types'
import { createGroups } from './utils'

const ActivityGroupWrapper = styled(Column)`
  margin-top: 16px;
  gap: 8px;
`

function TransactionsGroup({ transactions }: { transactions: TransactionDetails[] }) {
  const { chainId } = useWeb3React()
  const tokens = useAllTokensMultichain()
  const allTokens: Token[] = useAllLiquidityToken()

  const lpTokens = useMemo(() => {
    const temp: ChainTokenMap = {}
    allTokens.forEach((token) => {
      const temp2: any = temp[token.chainId] ?? {}
      _.assign(temp2, { [token.address]: token })
      temp[token.chainId] = temp2
    })
    return temp
  }, [allTokens])
  const combinedTokens = _.merge(lpTokens, tokens)
  const activites = transactions.map((transaction) =>
    transactionToActivity(transaction, chainId ?? ChainId.BLAST, combinedTokens)
  )
  const activityGroups = useMemo(() => (activites ? createGroups(activites as Activity[]) : []), [activites])

  if (activityGroups.length === 0) {
    return null
  } else {
    return (
      <>
        {activityGroups.map((activityGroup) => (
          <ActivityGroupWrapper key={activityGroup.title}>
            <ThemedText.SubHeader color="neutral2" marginLeft="16px">
              {activityGroup.title}
            </ThemedText.SubHeader>
            <Column data-testid="activity-content">
              {activityGroup.transactions.map((activity) => (
                <ActivityRow key={activity.hash} activity={activity} />
              ))}
            </Column>
          </ActivityGroupWrapper>
        ))}
      </>
    )
  }
}

export function ActivityTab() {
  const pendingTransactions = usePendingTransactions()
  const confirmedTransactions = useConfirmedTransactions()
  const toggleWalletDrawer = useToggleAccountDrawer()

  return !!pendingTransactions.length || !!confirmedTransactions.length ? (
    <>
      <TransactionsGroup transactions={pendingTransactions} />
      <TransactionsGroup transactions={confirmedTransactions} />
    </>
  ) : (
    <EmptyWalletModule type="activity" onNavigateClick={toggleWalletDrawer} />
  )
}
