import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import Row from 'components/Row'
import { MouseoverTooltip, TooltipSize } from 'components/Tooltip'
import { BIG_INT_ZERO } from 'constants/misc'
import { ChainId, CurrencyAmount, Percent, Token } from 'few-sdk-core-multiple-network-2'
import { Pair as FewPair } from 'few-v2-sdk-multiple-network-4'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import { useTotalSupply } from 'hooks/useTotalSupply'
import { useV2Pairs } from 'hooks/useV2Pairs'
import { useOriginalTokenOfFewToken } from 'hooks/useWrappedToken'
import JSBI from 'jsbi'
import { useTokenBalance, useTokenBalancesWithLoadingIndicator } from 'lib/hooks/useCurrencyBalance'
import { ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useNewStakingInfo } from 'state/stake/hooks'
import { toV2LiquidityToken, useFewTrackedTokenPairs } from 'state/user/hooks'
import { ThemedText } from 'theme/components'
import { currencyId } from 'utils/currencyId'
import { unwrappedToken } from 'utils/unwrappedToken'

import { EmptyWalletModule } from '../EmptyWalletContent'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'
import * as styles from './style.css'

export default function Pools({ account }: { account: string }) {
  const networkSupportsV2 = useNetworkSupportsV2()

  // fetch the user's balances of all tracked V2 LP tokens
  let fewTrackedTokenPairs = useFewTrackedTokenPairs()

  if (!networkSupportsV2) fewTrackedTokenPairs = []
  const tokenPairsWithLiquidityTokens = useMemo(
    () => fewTrackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [fewTrackedTokenPairs]
  )

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const [openPositions] = useMemo(() => {
    let openPositions = []
    openPositions = tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
      v2PairsBalances[liquidityToken.address]?.greaterThan('0')
    )
    return [openPositions]
  }, [tokenPairsWithLiquidityTokens, v2PairsBalances])

  const fewPairs = useV2Pairs(openPositions.map(({ tokens }) => tokens))

  const loading =
    fetchingV2PairBalances || fewPairs?.length < openPositions.length || fewPairs?.some((V2Pair) => !V2Pair)

  const filteredPositions = fewPairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is FewPair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useNewStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = filteredPositions.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  const toggleWalletDrawer = useToggleAccountDrawer()

  if (!filteredPositions || loading) {
    return <PortfolioSkeleton />
  }

  if (filteredPositions.length === 0) {
    return <EmptyWalletModule type="pool" onNavigateClick={toggleWalletDrawer} />
  }

  return (
    <PortfolioTabWrapper>
      {v2PairsWithoutStakedAmount.map((pair) => (
        <PositionListItem key={pair.liquidityToken.address + pair.liquidityToken.chainId} pair={pair} />
      ))}
      {stakingPairs.map(
        (stakingPair, i) =>
          stakingPair[1] && ( // skip pairs that arent loaded
            <PositionListItem
              key={stakingInfosWithBalance[i].stakingRewardAddress}
              pair={stakingPair[1]}
              stakedBalance={stakingInfosWithBalance[i].stakedAmount}
            />
          )
      )}
    </PortfolioTabWrapper>
  )
}

interface ButtonItemProps {
  href: string
  id?: string
  children: ReactNode
  dataTestId?: string
}

const ButtonItem = ({ href, dataTestId, id, children }: ButtonItemProps) => {
  return (
    <NavLink className={styles.buttonItem} to={href} id={id} data-testid={dataTestId}>
      {children}
    </NavLink>
  )
}

function PositionListItem({ pair, stakedBalance }: { pair: FewPair; stakedBalance?: CurrencyAmount<Token> }) {
  // const { chainId, position, pool, details, inRange, closed } = positionInfo
  const { account, chainId } = useWeb3React()
  // const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const originalTokens = [useOriginalTokenOfFewToken(pair.token0), useOriginalTokenOfFewToken(pair.token1)]
  const tokens = originalTokens.filter((token): token is Token => Boolean(token))

  const [token0, token1] = tokens

  const currency0 = token0 ? unwrappedToken(token0) : unwrappedToken(pair.token0)
  const currency1 = token1 ? unwrappedToken(token1) : unwrappedToken(pair.token1)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
      ? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
      : undefined

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_POOLS_ROW}
    >
      <PortfolioRow
        left={<PortfolioLogo chainId={chainId ?? ChainId.BLAST} currencies={[currency0, currency1]} />}
        title={
          <Row>
            <ThemedText.SubHeader>
              {currency0.symbol} / {currency1.symbol}
            </ThemedText.SubHeader>
          </Row>
        }
        descriptor={
          <ThemedText.BodySmall>
            <MouseoverTooltip
              placement="right"
              size={TooltipSize.ExtraSmall}
              text={
                <div style={{ padding: '4px 0px' }}>
                  <ThemedText.BodySmall>Your pool share</ThemedText.BodySmall>
                </div>
              }
            >
              {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
            </MouseoverTooltip>
          </ThemedText.BodySmall>
        }
        right={
          <>
            <MouseoverTooltip
              placement="left"
              size={TooltipSize.ExtraSmall}
              text={
                <div style={{ padding: '4px 0px' }}>
                  <ThemedText.BodySmall>Your total pool tokens</ThemedText.BodySmall>
                </div>
              }
            >
              <ThemedText.SubHeader marginRight="8px">
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </ThemedText.SubHeader>
            </MouseoverTooltip>
            <Row justify="flex-end" gap="0px">
              <ButtonItem href={`/add/few/${currencyId(currency0)}/${currencyId(currency1)}`}>
                <ThemedText.BodySmall>Add</ThemedText.BodySmall>
              </ButtonItem>
              <Row>
                <ThemedText.BodySmall color="neutral2" fontSize="10px">
                  ｜
                </ThemedText.BodySmall>
              </Row>
              <ButtonItem href={`/remove/few/${currencyId(currency0)}/${currencyId(currency1)}`}>
                <ThemedText.BodySmall>Remove</ThemedText.BodySmall>
              </ButtonItem>
            </Row>
          </>
        }
      />
    </TraceEvent>
  )
}
