import { ZERO_ADDRESS } from 'constants/misc'
import { Token } from 'few-sdk-core-multiple-network-2'
import { useFewTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { UNKNOWN_TOKEN_SYMBOL, useFewTokenFromOriginalToken, useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { useMemo } from 'react'
import { isAddress } from 'utils'

import { getWrappedTokenCreate2Address } from './Tokens'

export function isFewToken(token: Token | undefined | null): boolean {
  if (!token) {
    return false
  }

  const isFewWrappedName = (token.name ?? '').startsWith('Few Wrapped ')
  const isFwSymbol = (token.symbol ?? '').startsWith('fw')
  return isFewWrappedName && isFwSymbol
}

export function useWrappedToken(token: string | undefined | null, chainId: number): Token | null | undefined {
  const fewTokenAddress = useMemo(() => {
    if (!token) {
      return undefined
    }
    return getWrappedTokenCreate2Address(token, chainId)
  }, [token, chainId])

  const fewToken = useTokenFromActiveNetwork(fewTokenAddress === ZERO_ADDRESS ? undefined : fewTokenAddress)

  return useMemo(() => (fewToken && fewToken?.symbol != UNKNOWN_TOKEN_SYMBOL ? fewToken : undefined), [fewToken])
}

export function useWrappedTokenOfOriginalToken(
  token: string | undefined | null,
  chainId: number
): Token | null | undefined {
  const fewTokenAddress = useMemo(() => {
    if (!token) {
      return undefined
    }
    return getWrappedTokenCreate2Address(token, chainId)
  }, [token, chainId])

  const originalTokenAddress = useMemo(() => {
    if (!token) {
      return undefined
    }
    return token
  }, [token])

  const fewToken = useFewTokenFromOriginalToken(
    fewTokenAddress === ZERO_ADDRESS ? undefined : fewTokenAddress,
    originalTokenAddress
  )

  return useMemo(() => (fewToken && fewToken?.symbol != UNKNOWN_TOKEN_SYMBOL ? fewToken : undefined), [fewToken])
}

export function useWrappedTokenFromOriginal(token: Token | undefined): Token | undefined {
  const hasFewPrefix = useMemo(() => {
    const isFewWrappedName = token?.name?.startsWith('Few Wrapped ')
    const isFwSymbol = token?.symbol?.startsWith('fw')
    return isFewWrappedName || isFwSymbol
  }, [token])

  const fewTokenAddress = useMemo(() => {
    if (!token) {
      return undefined
    }
    return getWrappedTokenCreate2Address(token.address, token.chainId)
  }, [token])
  const fewSymbol = useMemo(() => `fw${token?.symbol}`, [token])

  const fewTokenName = useMemo(() => `Few Wrapped ${token?.name}`, [token])

  const fewToken = useMemo(() => {
    if (hasFewPrefix) {
      return token
    }

    if (!token || !fewTokenAddress) {
      return undefined
    }
    return new Token(token.chainId, fewTokenAddress, token.decimals, fewSymbol, fewTokenName)
  }, [fewSymbol, fewTokenAddress, fewTokenName, hasFewPrefix, token])

  return useMemo(() => (fewToken && fewToken?.symbol != UNKNOWN_TOKEN_SYMBOL ? fewToken : undefined), [fewToken])
}

export function useOriginalTokenOfFewToken(token?: Token): Token | undefined {
  const formattedAddress = isAddress(token?.address)
  const fewTokenContract = useFewTokenContract(formattedAddress ? formattedAddress : undefined, true)
  const originalTokenAddress: string | undefined = useSingleCallResult(
    fewTokenContract,
    'token'
  )?.result?.[0]?.toString()

  const originalToken = useTokenFromActiveNetwork(originalTokenAddress)

  return useMemo(
    () => (originalToken && token?.symbol != UNKNOWN_TOKEN_SYMBOL ? originalToken : undefined),
    [token, originalToken]
  )
}
