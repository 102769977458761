/* eslint-disable import/no-unused-modules */
import type { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import MerkleDistributorJSON from 'abis/MerkleDistributorWithDeadline.json'
import { ChainId, CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useEffect, useState } from 'react'

import { AirdropType, POINT, POINT_MERKLE_DISTRIBUTOR_ADDRESS } from '../../constants/tokens'
import { useContract } from '../../hooks/useContract'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import { useTransactionAdder } from '../transactions/hooks'
import { TransactionType } from '../transactions/types'

export function useMerkleDistributorContract(airdrop: AirdropType) {
  return useContract(POINT_MERKLE_DISTRIBUTOR_ADDRESS[airdrop], MerkleDistributorJSON.abi, true)
}

export interface UserClaimData {
  index: number
  amount: string
  proof: string[]
  flags?: {
    isSOCKS: boolean
    isLP: boolean
    isUser: boolean
  }
}

export type UserClaimListData = {
  [key in AirdropType]: UserClaimData
}

function fetchRingClaimMapping(key: string): Promise<UserClaimListData> {
  return fetch(`https://api-ring-airdrop.vercel.app/api/proof/${key}`)
    .then((res) => {
      return res.json().then((data) => {
        return data
      })
    })
    .catch((error) => {
      console.error('Failed to get claims mapping', error)
    })
}

// parse distributorContract blob and detect if user has claim data
// null means we know it does not
// eslint-disable-next-line import/no-unused-modules
export function useUserClaimData(account: string | null | undefined): UserClaimListData | null {
  const { chainId } = useWeb3React()

  const [claimInfo, setClaimInfo] = useState<{ [account: string]: UserClaimListData | null }>({})

  useEffect(() => {
    if (!account || chainId === ChainId.BLAST_SEPOLIA) return

    fetchRingClaimMapping(account)
      .then((accountClaimInfo) => {
        setClaimInfo((claimInfo) => {
          return {
            ...claimInfo,
            [account]: accountClaimInfo,
          }
        })
      })
      .catch(() => {
        setClaimInfo((claimInfo) => {
          return {
            ...claimInfo,
            [account]: null,
          }
        })
      })
  }, [account, chainId])

  return account && chainId !== ChainId.BLAST_SEPOLIA ? claimInfo[account] : null
}

// check if user is in blob and has not yet claimed UNI
export function useUserHasAvailableClaim(account: string | null | undefined, airdrop: AirdropType): boolean {
  const userClaimData = useUserClaimData(account)
  const distributorContract = useMerkleDistributorContract(airdrop)
  const index = userClaimData?.[airdrop]?.index === -1 ? undefined : userClaimData?.[airdrop]?.index
  const isClaimedResult = useSingleCallResult(distributorContract, 'isClaimed', [index])
  // user is in blob and contract marks as unclaimed
  return Boolean(userClaimData && !isClaimedResult.loading && isClaimedResult.result?.[0] === false)
}

export function useUserUnclaimedAmount(
  account: string | null | undefined,
  airdrop: AirdropType
): CurrencyAmount<Token> | undefined {
  const { chainId } = useWeb3React()
  const userClaimData = useUserClaimData(account)
  const canClaim = useUserHasAvailableClaim(account, airdrop)

  const uni = chainId ? POINT[chainId] : undefined
  if (!uni) return undefined
  if (!canClaim || !userClaimData) {
    return CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(0))
  }
  return CurrencyAmount.fromRawAmount(uni, JSBI.BigInt(userClaimData[airdrop].amount))
}

export function useClaimCallback(
  account: string | null | undefined,
  airdrop: AirdropType
): {
  claimCallback: () => Promise<string>
} {
  // get claim data for this account
  const { provider, chainId } = useWeb3React()
  const claimData = useUserClaimData(account)

  // used for popup summary
  const unclaimedAmount: CurrencyAmount<Token> | undefined = useUserUnclaimedAmount(account, airdrop)
  const addTransaction = useTransactionAdder()
  const distributorContract = useMerkleDistributorContract(airdrop)

  const claimCallback = async function () {
    if (!claimData || !account || !provider || !chainId || !distributorContract) return

    const args = [claimData[airdrop].index, account, claimData[airdrop].amount, claimData[airdrop].proof]

    return distributorContract.estimateGas['claim'](...args, {}).then((estimatedGasLimit) => {
      return distributorContract
        .claim(...args, { value: null, gasLimit: calculateGasMargin(estimatedGasLimit) })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.CLAIM,
            recipient: account,
            uniAmountRaw: unclaimedAmount?.quotient.toString(),
          })
          return response.hash
        })
    })
  }

  return { claimCallback }
}
