import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { BrowserEvent, InterfaceElementName, InterfaceEventName } from '@uniswap/analytics-events'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { SwitchLocaleLink } from 'components/SwitchLocaleLink'
import { V2Unsupported } from 'components/V2Unsupported'
import { Currency, CurrencyAmount, Percent } from 'few-sdk-core-multiple-network-2'
import { useNetworkSupportsMemeLaunchPad } from 'hooks/useNetworkSupportsMemeLaunchPad'
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Text } from 'rebass'
import { useMemeDerivedMintInfo, useMintMemeActionHandlers, useMintMemeState } from 'state/mint/meme/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import MemeBuyTokenInputPanel from '../../components/CurrencyInputPanel/memeBuyTokenInputPanel'
import CurrencyInputPanel from '../../components/CurrencyInputPanel/memeInputPanel'
import MemeNumberInputPanel from '../../components/CurrencyInputPanel/memeNumberInputPanel'
import { MemeLaunchPadTabs } from '../../components/NavigationTabs/memeLaunchPad'
import { RowBetween } from '../../components/Row'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import { WRAPPED_NATIVE_CURRENCY } from '../../constants/tokens'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useRingLaunchpadContract } from '../../hooks/useContract'
import { Field } from '../../state/mint/meme/actions'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { useUserSlippageToleranceWithDefault } from '../../state/user/hooks'
import { ThemedText } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pool/styled'
import { ConfirmMemeLaunchPadModalBottom } from './ConfirmAddModalBottom'

const DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE = new Percent(50, 10_000)

const InfoIcon = styled.span`
  margin-left: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.neutral2};
  color: ${({ theme }) => theme.neutral1};
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  cursor: help;
  position: relative;

  &:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 130%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text1};
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
`

export default function MemeTokenLaunchpad() {
  const { currencyIdA } = useParams<{ currencyIdA?: string }>()
  const navigate = useNavigate()
  const { account, chainId, provider } = useWeb3React()

  const currencyA = useCurrency(currencyIdA)
  console.log(currencyA, 'currencyA')
  const wrappedNativeCurrency = chainId ? WRAPPED_NATIVE_CURRENCY[chainId] : undefined

  const oneCurrencyIsWETH = Boolean(
    chainId && wrappedNativeCurrency && currencyA && currencyA.equals(wrappedNativeCurrency)
  )

  const toggleWalletDrawer = useToggleAccountDrawer() // toggle wallet when disconnected

  const {
    totalSupplyField,
    pairAmountField,
    buyMemeField,
    totalSupplyTypedValue,
    pairAmountTypedValue,
    buyTokenTypedValue,
  } = useMintMemeState()

  // const {
  //   dependentField,
  //   currencies,
  //   fewPair,
  //   fewPairState,
  //   currencyBalances,
  //   parsedAmounts,
  //   price,
  //   noLiquidity,
  //   liquidityMinted,
  //   poolTokenPercentage,
  //   error,
  // } = useNewFewDerivedMintInfo(currencyA ?? undefined, undefined)

  const {
    // totalSupplyField,
    // pairAmountField,
    // buyMemeField,
    currencies,
    currencyBalances,
    parsedAmounts,
    parsedTokenWords,
    error,
  } = useMemeDerivedMintInfo(currencyA ?? undefined)

  // const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)
  const {
    onFieldNameInput,
    onFieldSymbolInput,
    onFieldTotalSupplyInput,
    onFieldPairAmountInput,
    onFieldBuyTokenInput,
  } = useMintMemeActionHandlers(false)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_ADD_V2_SLIPPAGE_TOLERANCE) // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [buyMemeField]: buyTokenTypedValue,
    [totalSupplyTypedValue]: totalSupplyTypedValue,
    [pairAmountTypedValue]: pairAmountTypedValue,
    [buyMemeField]: buyTokenTypedValue,
    // [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.BUY_MEME_CURRENCY].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.BUY_MEME_CURRENCY].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  // const router = useFewV1RouterContract()
  const memeTokenLaunchpadContract = useRingLaunchpadContract()

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.BUY_MEME_CURRENCY],
    memeTokenLaunchpadContract?.address
  )
  // const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], router?.address)

  const addTransaction = useTransactionAdder()
  const networkSupportsMeme = useNetworkSupportsMemeLaunchPad()

  async function onDeploy() {
    if (!chainId || !provider || !account || !memeTokenLaunchpadContract || !networkSupportsMeme || !currencyA) return

    const {
      [Field.TOTAL_SUPPLY_AMOUNT]: parsedTotalSupply,
      [Field.PAIR_AMOUNT]: parsedPairAmount,
      [Field.BUY_MEME_CURRENCY]: parsedBuyTokenAmount,
    } = parsedAmounts

    const { [Field.NAME]: parsedName, [Field.SYMBOL]: parsedSymbol } = parsedTokenWords

    if (!parsedName || !parsedSymbol || !parsedPairAmount) {
      return
    }

    // const amountsMin = {
    //   [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
    //   [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? ZERO_PERCENT : allowedSlippage)[0],
    // }

    let estimate,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number>,
      value: BigNumber | null
    if (isBuyToken && parsedBuyTokenAmount) {
      if (currencyA.isNative) {
        estimate = memeTokenLaunchpadContract.estimateGas.deployETH
        method = memeTokenLaunchpadContract.deployETH
        args = [
          parsedName, // token
          parsedSymbol, // token desired
          // parsedTotalSupply.quotient.toString(), // token min
          parsedPairAmount.quotient.toString(), // eth min
          account,
        ]
        value = BigNumber.from(parsedBuyTokenAmount.quotient.toString())
      } else {
        estimate = memeTokenLaunchpadContract.estimateGas.deployWETH
        method = memeTokenLaunchpadContract.deployWETH
        args = [
          parsedName, // token
          parsedSymbol, // token desired
          // parsedTotalSupply.quotient.toString(), // token min
          parsedPairAmount.quotient.toString(), // eth min
          parsedBuyTokenAmount.quotient.toString(),
          account,
        ]
        value = null
      }
    } else {
      estimate = memeTokenLaunchpadContract.estimateGas.deploy
      method = memeTokenLaunchpadContract.deploy
      args = [
        parsedName, // token
        parsedSymbol, // token desired
        // parsedTotalSupply.quotient.toString(), // token min
        parsedPairAmount.quotient.toString(), // eth min
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            type: TransactionType.DEPLOY_MEME,
            tokenSymbol: parsedSymbol,
            tokenAddress: 'ETH',
            buyAmount: parsedBuyTokenAmount?.quotient.toString(),
          })

          setTxHash(response.hash)
        })
      )
      .catch((error) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }

  const modalHeader = () => {
    return (
      <AutoColumn gap="20px">
        <ThemedText.DeprecatedItalic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
          <Trans>
            Output is estimated. If the price changes by more than {allowedSlippage.toSignificant(4)}% your transaction
            will revert.
          </Trans>
        </ThemedText.DeprecatedItalic>
      </AutoColumn>
    )
  }

  const parsedBuyTokenAmount = parseFloat(formattedAmounts[Field.BUY_MEME_CURRENCY].toString())
  const isBuyToken = !isNaN(parsedBuyTokenAmount) && parsedBuyTokenAmount > 0

  const modalBottom = () => {
    return (
      <ConfirmMemeLaunchPadModalBottom
        isBuyToken={isBuyToken}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        tokenSymbol={parsedTokenWords.SYMBOL}
        onDeploy={onDeploy}
      />
    )
  }

  const pendingText = <Trans>Deploying</Trans>

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      // const newCurrencyIdA = currencyId(currencyA)
      navigate(`/ringLaunchpad/${currencyIdA}`)
      // if (newCurrencyIdA === currencyIdB) {
      //   navigate(`/add/few/${currencyIdB}/${currencyIdA}`)
      // } else {
      //   navigate(`/add/few/${newCurrencyIdA}/${currencyIdB}`)
      // }
    },
    [navigate, currencyIdA]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldBuyTokenInput('')
    }
    setTxHash('')
  }, [onFieldBuyTokenInput, txHash])

  // const addIsUnsupported = useIsSwapUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  if (!networkSupportsMeme) return <V2Unsupported />

  return (
    <>
      <AppBody>
        <MemeLaunchPadTabs />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            reviewContent={() => (
              <ConfirmationModalContent
                title={<Trans>You are launching a {parsedTokenWords.SYMBOL} token</Trans>}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
            currencyToAdd={undefined}
          />
          <AutoColumn gap="20px">
            <ColumnCenter>
              <BlueCard>
                <AutoColumn gap="10px">
                  <ThemedText.DeprecatedLink fontWeight={485} color="accent1">
                    <b>
                      <Trans>Tip:</Trans>
                    </b>{' '}
                    <Trans>
                      Ring.fun provides free initial liquidity for your token, so you can trade from day one!
                    </Trans>
                  </ThemedText.DeprecatedLink>
                </AutoColumn>
              </BlueCard>
            </ColumnCenter>
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Token Name</span>
                <InfoIcon data-tooltip="Token Name only supports alphabets, Arabic numerals are not allowed">
                  ?
                </InfoIcon>
              </div>
              <CurrencyInputPanel
                value={formattedAmounts[Field.NAME]}
                onUserInput={onFieldNameInput}
                onMax={() => {
                  onFieldNameInput(maxAmounts[Field.NAME]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.NAME]}
                currency={currencies[Field.NAME] ?? null}
                id="add-liquidity-input-tokena"
                showCommonBases
              />
            </>
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Token Symbol</span>
                <InfoIcon data-tooltip="Token Symbol only supports alphabets, Arabic numerals are not allowed">
                  ?
                </InfoIcon>
              </div>
              <CurrencyInputPanel
                value={formattedAmounts[Field.SYMBOL]}
                onUserInput={onFieldSymbolInput}
                onMax={() => {
                  onFieldSymbolInput(maxAmounts[Field.SYMBOL]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.SYMBOL]}
                currency={currencies[Field.SYMBOL] ?? null}
                id="add-liquidity-input-tokena"
                showCommonBases
              />
            </>
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Token Pair Amount</span>
                <InfoIcon data-tooltip="Token Pair Amount is the ETH amount to pair with tokens for initial liquidity. Max: 10 ETH.">
                  ?
                </InfoIcon>
              </div>
              <MemeNumberInputPanel
                value={formattedAmounts[Field.PAIR_AMOUNT]}
                onUserInput={onFieldPairAmountInput}
                onMax={() => {
                  onFieldPairAmountInput(maxAmounts[Field.PAIR_AMOUNT]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.PAIR_AMOUNT]}
                currency={currencies[Field.PAIR_AMOUNT] ?? null}
                id="add-liquidity-input-tokena"
                showCommonBases
              />
            </>
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Buy Token Amount</span>
                <InfoIcon data-tooltip="Enter an amount  and select a token (e.g., ETH). This amount will be used to automatically purchase your MEME tokens upon launch!">
                  ?
                </InfoIcon>
              </div>
              <MemeBuyTokenInputPanel
                value={formattedAmounts[Field.BUY_MEME_CURRENCY]}
                onUserInput={onFieldBuyTokenInput}
                onMax={() => {
                  onFieldBuyTokenInput(maxAmounts[Field.BUY_MEME_CURRENCY]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.BUY_MEME_CURRENCY]}
                currency={currencies[Field.BUY_MEME_CURRENCY] ?? null}
                id="add-liquidity-input-tokena"
                showCommonBases
              />
            </>
            {!account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: false }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonLight onClick={toggleWalletDrawer}>
                  <Trans>Connect Wallet</Trans>
                </ButtonLight>
              </TraceEvent>
            ) : (
              <AutoColumn gap="md">
                {(approvalA === ApprovalState.NOT_APPROVED || approvalA === ApprovalState.PENDING) && isValid && (
                  <RowBetween>
                    {/* {approvalA !== ApprovalState.APPROVED && ( */}
                    <ButtonPrimary
                      onClick={approveACallback}
                      disabled={approvalA === ApprovalState.PENDING}
                      width="100%"
                    >
                      {approvalA === ApprovalState.PENDING ? (
                        <Dots>
                          <Trans>Approving {currencies[Field.BUY_MEME_CURRENCY]?.symbol}</Trans>
                        </Dots>
                      ) : (
                        <Trans>Approve {currencies[Field.BUY_MEME_CURRENCY]?.symbol}</Trans>
                      )}
                    </ButtonPrimary>
                    {/* )} */}
                  </RowBetween>
                )}
                <ButtonError
                  onClick={() => {
                    setShowConfirm(true)
                  }}
                  disabled={!isValid}
                  error={!isValid && !!parsedAmounts[Field.BUY_MEME_CURRENCY]}
                >
                  <Text fontSize={20} fontWeight={535}>
                    {error ?? <Trans>Launch</Trans>}
                  </Text>
                </ButtonError>
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
      <SwitchLocaleLink />
    </>
  )
}
