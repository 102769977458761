import { Navigate, useParams } from 'react-router-dom'

import AddLiquidityFew from './index'

// eslint-disable-next-line import/no-unused-modules
export function RedirectDuplicateTokenBuyMemeToken() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA: string; currencyIdB: string }>()

  if (currencyIdA && currencyIdB && currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Navigate to={`ringLaunchpad/${currencyIdA}`} replace />
  }

  return <AddLiquidityFew />
}
