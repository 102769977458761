import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import FixedStakingRewardsJSON from 'abis/FixedStakingRewards.json'
import { BIG_INT_SECONDS_IN_YEAR } from 'constants/misc'
// import StakingRewardsJson from '@uniswap/liquidity-staker/build/StakingRewards.json'
import { CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'
import { Pair } from 'few-v2-sdk-multiple-network-4'
import { useFewStakeTokenPermit } from 'hooks/useFewStakeTokenPermit'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { ApprovalState, useApproveCallback } from '../../../hooks/useApproveCallback'
import { useContract, usePairContract } from '../../../hooks/useContract'
import useTransactionDeadline from '../../../hooks/useTransactionDeadline'
import { StakingInfo, useDerivedStakeInfo } from '../../../state/stake/hooks'
import { useTransactionAdder } from '../../../state/transactions/hooks'
import { TransactionType } from '../../../state/transactions/types'
import { CloseIcon, ThemedText } from '../../../theme'
import { formatCurrencyAmount } from '../../../utils/formatCurrencyAmount'
import { maxAmountSpend } from '../../../utils/maxAmountSpend'
import { ButtonConfirmed, ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import CurrencyInputPanel from '../../CurrencyInputPanel'
import Modal from '../../Modal'
import { LoadingView, SubmittedView } from '../../ModalViews'
import ProgressCircles from '../../ProgressSteps'
import { RowBetween } from '../../Row'

// const { abi: STAKING_REWARDS_ABI } = StakingRewardsJson
const { abi: FEW_STAKING_REWARDS_ABI } = FixedStakingRewardsJSON

// function useStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
//   return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible)
// }

function useFewStakingContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(stakingAddress, FEW_STAKING_REWARDS_ABI, withSignerIfPossible)
}

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo
  userLiquidityUnstaked?: CurrencyAmount<Token>
}

export default function StakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { provider } = useWeb3React()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedValue,
    stakingInfo.stakedAmount.currency,
    userLiquidityUnstaked
  )
  const parsedAmountWrapped = parsedAmount?.wrapped

  let hypotheticalRewardRate: CurrencyAmount<Token> = CurrencyAmount.fromRawAmount(stakingInfo.rewardRate.currency, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  function wrappedOnDismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  // pair contract for this token to be staked
  const dummyPair = new Pair(
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[0], '0'),
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[1], '0')
  )
  const pairContract = usePairContract(dummyPair.liquidityToken.address)

  // approval data for stake
  const deadline = useTransactionDeadline()
  // const { signatureData, gatherPermitSignature } = useV2LiquidityTokenPermit(parsedAmountWrapped, router?.address)
  const { signatureData, gatherPermitSignature } = useFewStakeTokenPermit(
    parsedAmountWrapped,
    stakingInfo.stakingRewardAddress
  )

  const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)

  const stakingContract = useFewStakingContract(stakingInfo.stakingRewardAddress)

  async function onStake() {
    if (!stakingContract || !parsedAmount || !deadline) throw new Error('no stakingContract')

    const index = stakingInfo.index

    const estimate = stakingContract.estimateGas.stake
    const method: (...args: any) => Promise<TransactionResponse> = stakingContract.stake
    const args = [index, `0x${parsedAmount.quotient.toString(16)}`]

    setAttempting(true)
    if (approval === ApprovalState.APPROVED) {
      await estimate(...args, {})
        .then((estimatedGasLimit) =>
          method(...args, {
            ...{},
            gasLimit: calculateGasMargin(estimatedGasLimit),
          }).then((response) => {
            setAttempting(false)

            addTransaction(response, {
              type: TransactionType.STAKE,
            })

            setHash(response.hash)
          })
        )
        .catch((error: any) => {
          setAttempting(false)
          // we only care if the error is something _other_ than the user rejected the tx
          if (error?.code !== 4001) {
            console.error(error)
          }
        })
    } else {
      setAttempting(false)
      throw new Error('Attempting to stake without approval or a signature. Please contact support.')
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue)
  }, [])

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  async function onAttemptToApprove() {
    if (!pairContract || !provider || !deadline) throw new Error('missing dependencies')
    if (!parsedAmount) throw new Error('missing liquidity amount')

    if (gatherPermitSignature) {
      try {
        await approveCallback()
      } catch (error) {
        setAttempting(false)
        console.log(error)
      }
    } else {
      await approveCallback().catch((error: any) => {
        setAttempting(false)
        console.log(error)
      })
    }
  }

  const governanceToken =
    stakingInfo.stakingRewardAddress === '0x8c90691f362b798d3bafd3c68469f8af5d5bbeed' ? 'RGB' : 'POINT'

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Deposit</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.stakedAmount.currency}
            // pair={dummyPair}
            label=""
            renderBalance={(amount) => <Trans>Available to deposit: {formatCurrencyAmount(amount, 4)}</Trans>}
            id="stake-liquidity-token"
          />

          <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <ThemedText.DeprecatedBlack fontWeight={600}>
                <Trans>Weekly Rewards</Trans>
              </ThemedText.DeprecatedBlack>
            </div>

            <ThemedText.DeprecatedBlack>
              <Trans>
                {hypotheticalRewardRate
                  .multiply(BIG_INT_SECONDS_IN_YEAR.toString())
                  .toSignificant(4, { groupSeparator: ',' })}{' '}
                {governanceToken} / year
              </Trans>
            </ThemedText.DeprecatedBlack>
          </HypotheticalRewardRate>

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={approval === ApprovalState.APPROVED || signatureData !== null}
              disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
            >
              <Trans>Approve</Trans>
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED)}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? <Trans>Deposit</Trans>}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Depositing Liquidity</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>{parsedAmount?.toSignificant(4)} RING LP</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="md" justify="center">
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>Deposited {parsedAmount?.toSignificant(4)} RING LP</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
