import { useWeb3React } from '@web3-react/core'
import { ChainId, CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'

import { PermitInfo, PermitType, useERC20Permit } from './useERC20Permit'
import useTransactionDeadline from './useTransactionDeadline'

const TEST_NET_REMOVE_V2_LIQUIDITY_PERMIT_INFO: PermitInfo = {
  version: '1',
  name: 'Uniswap V2',
  type: PermitType.AMOUNT,
}

const BLAST_MAINNET_REMOVE_V2_LIQUIDITY_PERMIT_INFO: PermitInfo = {
  version: '1',
  name: 'Ring V2',
  type: PermitType.AMOUNT,
}

export function useV2LiquidityTokenPermit(
  liquidityAmount: CurrencyAmount<Token> | null | undefined,
  spender: string | null | undefined
) {
  const { chainId } = useWeb3React()
  const transactionDeadline = useTransactionDeadline()
  return useERC20Permit(
    liquidityAmount,
    spender,
    transactionDeadline,
    chainId === ChainId.BLAST ? BLAST_MAINNET_REMOVE_V2_LIQUIDITY_PERMIT_INFO : TEST_NET_REMOVE_V2_LIQUIDITY_PERMIT_INFO
  )
}
