import { useWeb3React } from '@web3-react/core'
import { Currency } from 'few-sdk-core-multiple-network-2'
import { useMemo } from 'react'
import { useAllCombinedActiveList } from 'state/lists/hooks'

import { useOriginalTokenOfFewToken } from './useWrappedToken'

/** Returns a WrappedTokenInfo from the active token lists when possible, or the passed token otherwise. */
export function useTokenInfoFromActiveList(currency: Currency) {
  const { chainId } = useWeb3React()
  const activeList = useAllCombinedActiveList()

  return useMemo(() => {
    if (!chainId) return
    if (currency.isNative) return currency

    try {
      return activeList[chainId][currency.wrapped.address].token
    } catch (e) {
      return currency
    }
  }, [activeList, chainId, currency])
}

/** Returns a WrappedTokenInfo from the active token lists when possible, or the passed token otherwise. */
export function useOriginalTokenInfoFromActiveList(currency: Currency) {
  const { chainId } = useWeb3React()
  const activeList = useAllCombinedActiveList()
  const originalToken = useOriginalTokenOfFewToken(currency.wrapped)

  return useMemo(() => {
    if (!chainId || !originalToken) return
    if (currency.isNative) return currency

    try {
      return activeList[chainId][originalToken.address].token
    } catch (e) {
      return originalToken
    }
  }, [activeList, chainId, currency, originalToken])
}
