/* eslint-disable import/no-unused-modules */
import { Currency, Ether, NativeCurrency, Token, WETH9 } from 'few-sdk-core-multiple-network-2'
import { ChainId } from 'few-sdk-core-multiple-network-2'
import { constructSameAddressMap } from 'smart-order-router-multiple-network-12'
import invariant from 'tiny-invariant'

export const NATIVE_CHAIN_ID = 'NATIVE'

// When decimals are not specified for an ERC20 token
// use default ERC20 token decimals as specified here:
// https://docs.openzeppelin.com/contracts/3.x/erc20
export const DEFAULT_ERC20_DECIMALS = 18

export const USDC_MAINNET = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
const USDC_GOERLI = new Token(ChainId.GOERLI, '0x22Fe0875A343B319a8ca0e61C958150aD4321BF0', 6, 'USDC', 'USD//C')
const USDC_SEPOLIA = new Token(ChainId.SEPOLIA, '0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5', 6, 'USDC', 'USD//C')
const FEW_USDC_GOERLI = new Token(
  ChainId.GOERLI,
  '0x8b3a44eF4C19a9eAf0fDc921E2b6c2303c25A64A',
  18,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
const FEW_USDT_GOERLI = new Token(
  ChainId.GOERLI,
  '0xFb5c10Ffe9002944D8d3ED2f88af8D23A4080c85',
  18,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
const FEW_DAI_GOERLI = new Token(
  ChainId.GOERLI,
  '0xE2f2F3795666C69e65843522BDbb4c57e6403C50',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
const FEW_RNG_GOERLI = new Token(
  ChainId.GOERLI,
  '0xe6B8dDee63CaD05f686d5e66Ba18893AF366CFa7',
  18,
  'fwRNG',
  'Few Wrapped Ring Exchange'
)
export const USDC_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
  6,
  'USDC',
  'USD//C'
)
const USDC_OPTIMISM_GOERLI = new Token(
  ChainId.OPTIMISM_GOERLI,
  '0x7E07E15D2a87A24492740D16f5bdF58c16db0c4E',
  6,
  'USDC',
  'USD//C'
)
export const BRIDGED_USDC_ARBITRUM = new Token(
  ChainId.ARBITRUM_ONE,
  '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  6,
  'USDC',
  'USD//C'
)
export const USDC_ARBITRUM = new Token(
  ChainId.ARBITRUM_ONE,
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  6,
  'USDC',
  'USD//C'
)
export const USDC_ARBITRUM_GOERLI = new Token(
  ChainId.ARBITRUM_GOERLI,
  '0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892',
  6,
  'USDC',
  'USD//C'
)
export const USDC_POLYGON = new Token(
  ChainId.POLYGON,
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  6,
  'USDC',
  'USD//C'
)
const USDC_POLYGON_MUMBAI = new Token(
  ChainId.POLYGON_MUMBAI,
  '0xe11a86849d99f524cac3e7a0ec1241828e332c62',
  6,
  'USDC',
  'USD//C'
)
export const PORTAL_USDC_CELO = new Token(
  ChainId.CELO,
  '0x37f750B7cC259A2f741AF45294f6a16572CF5cAd',
  6,
  'USDCet',
  'USDC (Portal from Ethereum)'
)
export const USDC_BASE = new Token(
  ChainId.BASE,
  '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
  6,
  'USD Base Coin',
  'USDbC'
)
export const USDC_NATIVE_BASE = new Token(
  ChainId.BASE,
  '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  6,
  'USD Coin',
  'USDC'
)
const USDC_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0xF19A5b56b419170Aa2ee49E5c9195F5902D39BF1',
  6,
  'USDC',
  'USD//C'
)
export const DAI_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x9C6Fc5bF860A4a012C9De812002dB304AD04F581',
  18,
  'DAI',
  'Dai Stablecoin'
)
// eslint-disable-next-line import/no-unused-modules
export const USDT_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0xD8F542D710346DF26F28D6502A48F49fB2cFD19B',
  18,
  'USDT',
  'Tether USD'
)
export const USDB_BLAST = new Token(ChainId.BLAST, '0x4300000000000000000000000000000000000003', 18, 'USDB', 'USDB')
export const USDB_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x4200000000000000000000000000000000000022',
  18,
  'USDB',
  'USDB'
)
const FEW_USDC_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0xa7870cf9143084ED04f4C2311f48CB24a2b4A097',
  18,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
const FEW_USDT_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0xD8f6A67D198485335DAF4aaDeb74358BC021410d',
  18,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
const FEW_DAI_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x9DB240312deEFEC82687405a4CF42511032d55d8',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
const FEW_USDB_BLAST = new Token(
  ChainId.BLAST,
  '0x866f2C06B83Df2ed7Ca9C2D044940E7CD55a06d6',
  18,
  'fwUSDB',
  'Few Wrapped USDB'
)
const FEW_ETHX_BLAST = new Token(
  ChainId.BLAST,
  '0x09f1917D968A3eE1355e61Fb6A0347de614116AF',
  18,
  'fwETHx',
  'Few Wrapped Wand ETHx'
)
const FEW_OMNI_BLAST = new Token(
  ChainId.BLAST,
  '0x0c62b12cbc5ffbcdb3a0a90cce1aff51b79ff5fb',
  18,
  'fwOMNI',
  'Few Wrapped OmniCat'
)
const FEW_JUICE_BLAST = new Token(
  ChainId.BLAST,
  '0x30c7b9F4CD589203d250aD165651e82E338CB5bB',
  18,
  'fwJUICE',
  'Few Wrapped Juice'
)
const FEW_WBTC_BLAST = new Token(
  ChainId.BLAST,
  '0x97eCAD323F63060DF37FF0Cf05350803dB4D17Bd',
  8,
  'fwWBTC',
  'Few Wrapped Wrapped BTC'
)
const FEW_WBTC_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0x0c5EC8a8AE241091A5AcB8fFaA165895E0A9aEc7',
  8,
  'fwWBTC',
  'Few Wrapped Wrapped BTC'
)
const FEW_ORBIT_BLAST = new Token(
  ChainId.BLAST,
  '0x35b1cd11B684C7A9e1aA8d34e6B2a500310Ec35c',
  18,
  'fwORBIT',
  'Few Wrapped Orbit Protocol'
)
const FEW_DETH_BLAST = new Token(
  ChainId.BLAST,
  '0xB0de93a54dA8a2cfCDe44a06F797aB2fb9d39fB8',
  18,
  'fwDETH',
  'Few Wrapped Duo ETH'
)
const FEW_BLAST_TOKEN_BLAST = new Token(
  ChainId.BLAST,
  '0xb1E4E8B433C76C1fD42Bd3099a5E5817fff69fE4',
  18,
  'fwBLAST',
  'Few Wrapped Blast'
)
const FEW_DUSD_BLAST = new Token(
  ChainId.BLAST,
  '0x04d4fd245aCD3d7b3a1126ae27173FCa354f658B',
  18,
  'fwDUSD',
  'Few Wrapped Duo USD'
)
const FEW_USDB_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x4D2c669Cf7DC804641Cd4Dec3CD73daB5575c3Da',
  18,
  'fwUSDB',
  'Few Wrapped USDB'
)
export const FEW_RNG_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x187221b41f430faF3E418592746AE1a4f02b72EB',
  18,
  'fwRNG',
  'Few Wrapped Ring Exchange'
)
export const FEW_RNG_BLAST = new Token(
  ChainId.BLAST,
  '0x2Cd33E470004E4670ED5cfc22fa7Bad167d86982',
  18,
  'fwRNG',
  'Few Wrapped Ring Exchange'
)
export const FEW_RGB_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0x9BF7537cE9F808c845d5Cfe1e94c856A74Fa56d7',
  18,
  'fwRGB',
  'Few Wrapped Ring Governance Token'
)

export const FEW_RING_BLAST_SEPOLIA = new Token(
  ChainId.BLAST_SEPOLIA,
  '0xcd975BAa73D36b78EB6629ba05E92fa0f2bB3d84',
  18,
  'fwRING',
  'Few Wrapped Ring Governance Token'
)

export const FEW_RING_BLAST = new Token(
  ChainId.BLAST,
  '0x18755D2ceC785aB87680Edb8e117615E4B005430',
  18,
  'fwRING',
  'Few Wrapped Ring Governance Token'
)

export const FEW_RING_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0xC15bCBFA8ee06d98F0b7B14f6E04109bC751c622',
  18,
  'fwRING',
  'Few Wrapped Ring Governance Token'
)

export const DAI_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x5fbad067f69eBbc276410D78fF52823be133eD48',
  18,
  'DAI',
  'Dai Stablecoin'
)
const FEW_USDC_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x76AC72683C5b7F22C6B5Ed85B5B1511702464F7e',
  6,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
const FEW_USDC_MAINNET = new Token(
  ChainId.MAINNET,
  '0x0492560FA7Cfd6A85E50D8bE3F77318994F8f429',
  6,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
export const FEW_USDC_BASE = new Token(
  ChainId.BASE,
  '0x3f03d40b28c773649ab75ef6f4853e7f79dc9286',
  6,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
export const FEW_USDC_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0x21719B213646627698c8A5ADa5A6cf2Eb82C540a',
  6,
  'fwUSDC',
  'Few Wrapped USD Coin'
)
export const FEW_USDBC_BASE = new Token(
  ChainId.BASE,
  '0x1263e0908f4670C1D242b3a510e5B46f1962E47A',
  6,
  'fwUSDbC',
  'Few Wrapped USD Base Coin'
)

const FEW_USDT_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x8c4Fea1D8816B17BcbB33Cd629DF93ba5F76c96c',
  6,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
const FEW_USDT_MAINNET = new Token(
  ChainId.MAINNET,
  '0xef87f4608e601E8564800265AeE1c1FfaDF73283',
  6,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
export const FEW_USDT_BASE = new Token(
  ChainId.BASE,
  '0x6177eeccfbb3d4e0cc41b4e9de40a2daed236722',
  6,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
export const FEW_USDT_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0x49539A32510a4d1706eB1852Cc6a7Db6677dDc1b',
  6,
  'fwUSDT',
  'Few Wrapped Tether USD'
)
const FEW_DAI_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x09D8486e42Aa76229a563bFa0f07CA301aCd29C9',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
export const FEW_RNG_SEPOLIA = new Token(
  ChainId.SEPOLIA,
  '0x2E4370Fdc49D93C43820296D931E1A36446417C7',
  18,
  'fwRNG',
  'Few Wrapped Ring Exchange'
)
export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const FEW_DAI_MAINNET = new Token(
  ChainId.MAINNET,
  '0x8A6fe57C08C84e0f4eE97aAe68a62e820a37d259',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
export const FEW_DAI_BASE = new Token(
  ChainId.BASE,
  '0x8d9fd481ee2f5a1dd70ab5e4fc72715a293d62c3',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
export const FEW_DAI_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0x925dc2b62CD41a6318cC481752279b0a6D02D1D4',
  18,
  'fwDAI',
  'Few Wrapped Dai Stablecoin'
)
export const FEW_DAI_ARBITRUM_SEPOLIA = new Token(
  ChainId.ARBITRUM_SEPOLIA,
  '0xED43C9E67e04aD647563C53d4136f9F668402017',
  18,
  'fwDAI',
  'Few Wrapped DAI'
)
export const DAI_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
)
export const DAI_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin'
)
export const DAI_POLYGON = new Token(
  ChainId.POLYGON,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDT_POLYGON = new Token(
  ChainId.POLYGON,
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC_POLYGON = new Token(
  ChainId.POLYGON,
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const USDT_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  6,
  'USDT',
  'Tether USD'
)
export const USDT_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')
export const WBTC_ARBITRUM_ONE = new Token(
  ChainId.ARBITRUM_ONE,
  '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
  8,
  'WBTC',
  'Wrapped BTC'
)
export const WBTC_OPTIMISM = new Token(
  ChainId.OPTIMISM,
  '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
  8,
  'WBTC',
  'Wrapped BTC'
)

export const WETH_POLYGON_MUMBAI = new Token(
  ChainId.POLYGON_MUMBAI,
  '0xa6fa4fb5f76172d178d61b04b0ecd319c5d1c0aa',
  18,
  'WETH',
  'Wrapped Ether'
)

export const WETH_POLYGON = new Token(
  ChainId.POLYGON,
  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  18,
  'WETH',
  'Wrapped Ether'
)
const CELO_CELO = new Token(ChainId.CELO, '0x471EcE3750Da237f93B8E339c536989b8978a438', 18, 'CELO', 'Celo')
export const CUSD_CELO = new Token(
  ChainId.CELO,
  '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  18,
  'cUSD',
  'Celo Dollar'
)
export const CEUR_CELO = new Token(
  ChainId.CELO,
  '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
  18,
  'cEUR',
  'Celo Euro Stablecoin'
)
export const PORTAL_ETH_CELO = new Token(
  ChainId.CELO,
  '0x66803FB87aBd4aaC3cbB3fAd7C3aa01f6F3FB207',
  18,
  'ETH',
  'Portal Ether'
)
export const CMC02_CELO = new Token(
  ChainId.CELO,
  '0x32A9FE697a32135BFd313a6Ac28792DaE4D9979d',
  18,
  'cMCO2',
  'Celo Moss Carbon Credit'
)
const CELO_CELO_ALFAJORES = new Token(
  ChainId.CELO_ALFAJORES,
  '0xF194afDf50B03e69Bd7D057c1Aa9e10c9954E4C9',
  18,
  'CELO',
  'Celo'
)
export const CUSD_CELO_ALFAJORES = new Token(
  ChainId.CELO_ALFAJORES,
  '0x874069Fa1Eb16D44d622F2e0Ca25eeA172369bC1',
  18,
  'CUSD',
  'Celo Dollar'
)
export const CEUR_CELO_ALFAJORES = new Token(
  ChainId.CELO_ALFAJORES,
  '0x10c892A6EC43a53E45D0B916B4b7D383B1b78C0F',
  18,
  'CEUR',
  'Celo Euro Stablecoin'
)

export const USDC_BSC = new Token(ChainId.BNB, '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 18, 'USDC', 'USDC')
export const USDT_BSC = new Token(ChainId.BNB, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'USDT')
export const ETH_BSC = new Token(ChainId.BNB, '0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18, 'ETH', 'Ethereum')
export const BTC_BSC = new Token(ChainId.BNB, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'BTCB')
export const BUSD_BSC = new Token(ChainId.BNB, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'BUSD')
export const DAI_BSC = new Token(ChainId.BNB, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'DAI')

export const USDC_AVALANCHE = new Token(
  ChainId.AVALANCHE,
  '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
  6,
  'USDC',
  'USDC Token'
)
export const USDT_AVALANCHE = new Token(
  ChainId.AVALANCHE,
  '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
  6,
  'USDT',
  'Tether USD'
)
export const WETH_AVALANCHE = new Token(
  ChainId.AVALANCHE,
  '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
  18,
  'WETH',
  'Wrapped Ether'
)
export const DAI_AVALANCHE = new Token(
  ChainId.AVALANCHE,
  '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
  18,
  'DAI.e',
  'Dai.e Token'
)

export const DAI_ARB_SEPOLIA = new Token(
  ChainId.ARBITRUM_SEPOLIA,
  '0x932aB8a23E031BAf8AAA4203fDAEfd1B836e85A6',
  18,
  'DAI',
  'DAI'
)

export const UNI: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x4623f325d220C8118c0bf610b99ca8333c1A79FF',
    18,
    'RING',
    'Ring Governance Token'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0x33dCc49DFa5c8C9d7fa9BfF4038B2CC08Dd10E3B',
    18,
    'RING',
    'Ring Governance Token'
  ),
  [ChainId.BLAST]: new Token(
    ChainId.BLAST,
    '0x25F233C3E3676f9e900a89644a3FE5404d643c84',
    18,
    'RING',
    'Ring Governance Token'
  ),
  [ChainId.ARBITRUM_SEPOLIA]: new Token(
    ChainId.ARBITRUM_SEPOLIA,
    '0x042e56F81A6fdcfb64C6BeeD57298463421c69c5',
    18,
    'RING',
    'Ring Governance Token'
  ),
}

export enum AirdropType {
  RING_SNAPSHOTS = 'RING_SNAPSHOTS',
  GALXE = 'GALXE',
  BAC_GAMES = 'BAC_GAMES',
  BAC_GAMES_2 = 'BAC_GAMES_2',
  DAP_DAP = 'DAP_DAP',
  DISCORD = 'DISCORD',
  BITGET = 'BITGET',
  DUO = 'DUO',
  GALXE_EARN_LIQUIDITY = 'GALXE_EARN_LIQUIDITY',
  ORBIT_AND_PAC = 'ORBIT_AND_PAC',
}

export const POINT_MERKLE_DISTRIBUTOR_ADDRESS: { [key in AirdropType]: AddressMap } = {
  [AirdropType.BAC_GAMES]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0x3cc305d4Af8A616882659e9009c0d9AB20BDCAF7',
    [ChainId.BLAST]: '0xe0d539EfF781197A8F3dE9fD4DAA7968DAcD3C9C',
  },
  [AirdropType.RING_SNAPSHOTS]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0x3921a10BE84040794023000222b6F7109B00cFA0',
    [ChainId.BLAST]: '0x8A6FAb1921F92e1eDe7c3df98AC95512b53Fd5Bb',
  },
  [AirdropType.GALXE]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0x34c3Ae34ffb4199190eeEF5d8739Fb45E0DeA497',
    [ChainId.BLAST]: '0xd2081c8b3f5804DBbC64d8390D50401dc83d4817',
  },
  [AirdropType.DAP_DAP]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0x98Bb5b4A6Fac81761C761B9f179732c8D303D953',
    [ChainId.BLAST]: '0x1461E81108A080719b2b79EE8978671A8337002C',
  },
  [AirdropType.BAC_GAMES_2]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0xB6A6760Ec84dEA30f8671060106ff89051692a49',
    [ChainId.BLAST]: '0x30Ee0E22678057c7c5EF8b6b230A2Ed843EDb247',
  },
  [AirdropType.DISCORD]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0xa767FE994E373745f0070e591CA3b361F87F0A58',
    [ChainId.BLAST]: '0x87862f57bb7B72449eDc6C1277c5738894aB6127',
  },
  [AirdropType.BITGET]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0xF80c1241Ff8EA48d65A20A7C331d7A55934AFc47',
    [ChainId.BLAST]: '0x331E7E834689c110fFec5861d247c057604E4085',
  },
  [AirdropType.DUO]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0x3d1e64dC3B2c09Cf41a177494465b6496aAAbA3b',
    [ChainId.BLAST]: '0x73Fd727dE545B51Fc710B6e58b010c2F1D82c7C5',
  },
  [AirdropType.GALXE_EARN_LIQUIDITY]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0xC0562Ef1353278cEdeFB1aFFfF27e64881B787F4',
    [ChainId.BLAST]: '0x07cb17cB88388a5B95FBC5a4fC778740aD122beb',
  },
  [AirdropType.ORBIT_AND_PAC]: {
    [ChainId.BLAST_SEPOLIA]: '0x49c48bCd5DCeBd4e20cdc3F3fE69a117B7e29959',
    [ChainId.SEPOLIA]: '0xfac555cbb4117e9093008ce28A9ca52A9d0F86a6',
    [ChainId.BLAST]: '0x3bc6f14E3a52b180084c1602E650181c387c585D',
  },
}

// RGB Token was unsupported, use POINT token for instead
export const RING_CLAIMER_ADDRESS: AddressMap = {
  [168587773]: '0x7a8Ce24ED994276CB7c4C02C93F0B73498af215E',
  [11155111]: '0x1D753fB14b14F28fC7EF8eFF6bdB199B81746fDb',
  [81457]: '0xf72E9224B23aD19DF718caF43398dA0f1Dbc3e9c',
}

export const RGB: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x7647a41596c1Ca0127BaCaa25205b310A0436B4C',
    18,
    'RGB',
    'Ring Governance Token'
  ),
}

export const RING: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x4623f325d220C8118c0bf610b99ca8333c1A79FF',
    18,
    'RING',
    'Ring Governance Token'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0x33dCc49DFa5c8C9d7fa9BfF4038B2CC08Dd10E3B',
    18,
    'RING',
    'Ring Governance Token'
  ),
  [ChainId.BLAST]: new Token(
    ChainId.BLAST,
    '0x25F233C3E3676f9e900a89644a3FE5404d643c84',
    18,
    'RING',
    'Ring Governance Token'
  ),
}

export const POINT: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x0BD5539E33a1236bA69228271e60f3bFf8fDB7DB',
    18,
    'POINT',
    'Ring Point'
  ),
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x8bd2cdaDCe9c978af3DDbd6c0C8488bE1BfD7e94', 18, 'POINT', 'Ring Point'),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0x60735d0a0B731bD07f9fa8733f0F9B6c6eDA3Be7',
    18,
    'POINT',
    'Ring Point'
  ),
}

export const FEW_ORIGINAL_USDT: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: new Token(ChainId.GOERLI, '0x73EF924Ad649D80375077A7A5A30BDEE1fB733FA', 18, 'USDT', 'Tether USD'),
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0xD8F542D710346DF26F28D6502A48F49fB2cFD19B',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, '0x7db5fA1170D15e8D02deFB483b833EB1f3bAAC38', 6, 'USDT', 'Tether USD'),
}

export const FEW_ORIGINAL_USDC: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: new Token(ChainId.GOERLI, '0x22Fe0875A343B319a8ca0e61C958150aD4321BF0', 18, 'USDC', 'USD Coin'),
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0xF19A5b56b419170Aa2ee49E5c9195F5902D39BF1',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, '0xA7a151d60Bbd522d3611C2dB3F1F972Ee9904B3e', 6, 'USDC', 'USD Coin'),
}

export const FEW_ORIGINAL_DAI: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: new Token(
    ChainId.GOERLI,
    '0x794325B9dE05c48A7E29fa8AEE260719D97c6a2D',
    18,
    'DAI',
    'Dai Stablecoin'
  ),
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x9C6Fc5bF860A4a012C9De812002dB304AD04F581',
    18,
    'DAI',
    'Dai Stablecoin'
  ),
}

export const FEW_ORIGINAL_USDB: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x4300000000000000000000000000000000000003', 18, 'USDB', 'USDB'),
  [ChainId.BLAST_SEPOLIA]: USDB_BLAST_SEPOLIA,
}

export const FEW_ORIGINAL_ETHX: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0xd79d6Fe06F4C2b17291015169d1443f50D0C2838', 18, 'ETHx', 'Wand ETHx'),
}

export const FEW_ORIGINAL_OMNI: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x9e20461bc2c4c980f62f1B279D71734207a6A356', 18, 'OMNI', 'OmniCat'),
}

export const FEW_ORIGINAL_JUICE: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923', 18, 'JUICE', 'Juice'),
}

export const FEW_ORIGINAL_WBTC: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692', 8, 'WBTC', 'Wrapped BTC'),
}

export const FEW_ORIGINAL_ORBIT: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(
    ChainId.BLAST,
    '0x42E12D42b3d6C4A74a88A61063856756Ea2DB357',
    18,
    'ORBIT',
    'Orbit Protocol'
  ),
}

export const FEW_ORIGINAL_DETH: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x1Da40C742F32bBEe81694051c0eE07485fC630f6', 18, 'DETH', 'DUO ETH'),
}

export const FEW_ORIGINAL_BLAST: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad', 18, 'BLAST', 'Blast'),
}

export const FEW_ORIGINAL_RING: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(
    ChainId.BLAST,
    '0x25F233C3E3676f9e900a89644a3FE5404d643c84',
    18,
    'RING',
    'Ring Governance Token'
  ),
}

export const FEW_ORIGINAL_DUSD: { [chainId: number]: Token } = {
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x1A3D9B2fa5c6522c8c071dC07125cE55dF90b253', 18, 'DUSD', 'DUO USD'),
}

export const ARB = new Token(ChainId.ARBITRUM_ONE, '0x912CE59144191C1204E64559FE8253a0e49E6548', 18, 'ARB', 'Arbitrum')

export const OP = new Token(ChainId.OPTIMISM, '0x4200000000000000000000000000000000000042', 18, 'OP', 'Optimism')

export const WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<ChainId, Token>),
  [ChainId.OPTIMISM]: new Token(
    ChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.OPTIMISM_GOERLI]: new Token(
    ChainId.OPTIMISM_GOERLI,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.BASE]: new Token(ChainId.BASE, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM_GOERLI]: new Token(
    ChainId.ARBITRUM_GOERLI,
    '0xe39Ab88f8A4777030A534146A9Ca3B52bd5D43A3',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ARBITRUM_SEPOLIA]: new Token(
    ChainId.ARBITRUM_SEPOLIA,
    '0x980B62Da83eFf3D4576C647993b0c1D7faf17c73',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x4200000000000000000000000000000000000023',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.BLAST]: new Token(ChainId.BLAST, '0x4300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.POLYGON]: new Token(
    ChainId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [ChainId.POLYGON_MUMBAI]: new Token(
    ChainId.POLYGON_MUMBAI,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [ChainId.CELO]: new Token(
    ChainId.CELO,
    '0x471ece3750da237f93b8e339c536989b8978a438',
    18,
    'CELO',
    'Celo native asset'
  ),
  [ChainId.CELO_ALFAJORES]: new Token(
    ChainId.CELO_ALFAJORES,
    '0xf194afdf50b03e69bd7d057c1aa9e10c9954e4c9',
    18,
    'CELO',
    'Celo native asset'
  ),
  [ChainId.BNB]: new Token(ChainId.BNB, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    18,
    'WAVAX',
    'Wrapped AVAX'
  ),
}

export const WETH_BLAST = new Token(
  ChainId.BLAST,
  '0x4300000000000000000000000000000000000004',
  18,
  'WETH',
  'Wrapped Ether'
)

export const FWWETH_FWUSDB_PAIR_BLAST = new Token(
  ChainId.BLAST,
  '0x9BE8a40C9cf00fe33fd84EAeDaA5C4fe3f04CbC3',
  18,
  'RING-V2',
  'Ring V2'
)

export const FEW_WRAPPED_NATIVE_CURRENCY: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xa250CC729Bb3323e7933022a67B52200fE354767',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.BASE]: new Token(
    8453,
    '0xd38e4149df30AfFFEE13d771f3Ec9277c72333E4',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    42161,
    '0x76CDff74222533Efa4BAa713BC3d14df81A460F7',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.BLAST_SEPOLIA]: new Token(
    ChainId.BLAST_SEPOLIA,
    '0x798dE0520497E28E8eBfF0DF1d791c2E942eA881',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.BLAST]: new Token(
    ChainId.BLAST,
    '0x66714DB8F3397c767d0A602458B5b4E3C0FE7dd1',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0x98b902eF4f9fEB2F6982ceEB4E98761294854D61',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
  [ChainId.ARBITRUM_SEPOLIA]: new Token(
    ChainId.ARBITRUM_SEPOLIA,
    '0x1fc632A9A9c99ae7C406f8565cd709b52BA0ed47',
    18,
    'fwWETH',
    'Few Wrapped Wrapped Ether'
  ),
}

export function isCelo(chainId: number): chainId is ChainId.CELO | ChainId.CELO_ALFAJORES {
  return chainId === ChainId.CELO_ALFAJORES || chainId === ChainId.CELO
}

function getCeloNativeCurrency(chainId: number) {
  switch (chainId) {
    case ChainId.CELO_ALFAJORES:
      return CELO_CELO_ALFAJORES
    case ChainId.CELO:
      return CELO_CELO
    default:
      throw new Error('Not celo')
  }
}

export function isMatic(chainId: number): chainId is ChainId.POLYGON | ChainId.POLYGON_MUMBAI {
  return chainId === ChainId.POLYGON_MUMBAI || chainId === ChainId.POLYGON
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isMatic(this.chainId)) throw new Error('Not matic')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isMatic(chainId)) throw new Error('Not matic')
    super(chainId, 18, 'MATIC', 'Polygon Matic')
  }
}

export function isBsc(chainId: number): chainId is ChainId.BNB {
  return chainId === ChainId.BNB
}

class BscNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isBsc(this.chainId)) throw new Error('Not bnb')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isBsc(chainId)) throw new Error('Not bnb')
    super(chainId, 18, 'BNB', 'BNB')
  }
}

export function isAvalanche(chainId: number): chainId is ChainId.AVALANCHE {
  return chainId === ChainId.AVALANCHE
}

class AvaxNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId
  }

  get wrapped(): Token {
    if (!isAvalanche(this.chainId)) throw new Error('Not avalanche')
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    invariant(wrapped instanceof Token)
    return wrapped
  }

  public constructor(chainId: number) {
    if (!isAvalanche(chainId)) throw new Error('Not avalanche')
    super(chainId, 18, 'AVAX', 'AVAX')
  }
}

class ExtendedEther extends Ether {
  public get wrapped(): Token {
    const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId]
    if (wrapped) return wrapped
    throw new Error(`Unsupported chain ID: ${this.chainId}`)
  }

  private static _cachedExtendedEther: { [chainId: number]: NativeCurrency } = {}

  public static onChain(chainId: number): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId))
  }
}

const cachedNativeCurrency: { [chainId: number]: NativeCurrency | Token } = {}
export function nativeOnChain(chainId: number): NativeCurrency | Token {
  if (cachedNativeCurrency[chainId]) return cachedNativeCurrency[chainId]
  let nativeCurrency: NativeCurrency | Token
  if (isMatic(chainId)) {
    nativeCurrency = new MaticNativeCurrency(chainId)
  } else if (isCelo(chainId)) {
    nativeCurrency = getCeloNativeCurrency(chainId)
  } else if (isBsc(chainId)) {
    nativeCurrency = new BscNativeCurrency(chainId)
  } else if (isAvalanche(chainId)) {
    nativeCurrency = new AvaxNativeCurrency(chainId)
  } else {
    nativeCurrency = ExtendedEther.onChain(chainId)
  }
  return (cachedNativeCurrency[chainId] = nativeCurrency)
}

export function getSwapCurrencyId(currency: Currency): string {
  if (currency.isToken) {
    return currency.address
  }
  return NATIVE_CHAIN_ID
}

export const TOKEN_SHORTHANDS: { [shorthand: string]: { [chainId in ChainId]?: string } } = {
  USDC: {
    [ChainId.MAINNET]: USDC_MAINNET.address,
    [ChainId.ARBITRUM_ONE]: BRIDGED_USDC_ARBITRUM.address,
    [ChainId.ARBITRUM_GOERLI]: USDC_ARBITRUM_GOERLI.address,
    [ChainId.OPTIMISM]: USDC_OPTIMISM.address,
    [ChainId.OPTIMISM_GOERLI]: USDC_OPTIMISM_GOERLI.address,
    [ChainId.POLYGON]: USDC_POLYGON.address,
    [ChainId.POLYGON_MUMBAI]: USDC_POLYGON_MUMBAI.address,
    [ChainId.BNB]: USDC_BSC.address,
    [ChainId.CELO]: PORTAL_USDC_CELO.address,
    [ChainId.CELO_ALFAJORES]: PORTAL_USDC_CELO.address,
    [ChainId.GOERLI]: USDC_GOERLI.address,
    [ChainId.SEPOLIA]: USDC_SEPOLIA.address,
    [ChainId.AVALANCHE]: USDC_AVALANCHE.address,
    [ChainId.BLAST_SEPOLIA]: USDC_BLAST_SEPOLIA.address,
    [ChainId.BLAST]: USDB_BLAST.address,
  },
}

export const RNG_PAIR_TOKENS = [
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  '0xe41d2489571d322189246dafa5ebde1f4699f498',
  '0x6f40d4a6237c257fff2db00fa0510deeecd303eb',
  '0x808507121b80c02388fad14726482e061b8da827',
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  '0x514910771af9ca656af840dff83e8264ecf986ca',
  '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  '0x58b6a8a3302369daec383334672404ee733ab239',
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  '0xd533a949740bb3306d119cc777fa900ba034cd52',
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
  '0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d',
  '0x4fe83213d56308330ec302a8bd641f1d0113a4cc',
  '0xba100000625a3754423978a60c9317c58a424e3d',
  '0xf5cfbc74057c610c8ef151a439252680ac68c6dc',
  '0xcdf7028ceab81fa0c6971208e83fa7872994bee5',
  '0x9813037ee2218799597d83d4a5b6f3b6778218d9',
  '0xc00e94cb662c3520282e6f5717214004a7f26888',
  '0x6243d8cea23066d098a15582d81a598b4e8391f4',
  '0x77777feddddffc19ff86db637967013e6c6a116c',
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
  '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
  '0x375abb85c329753b1ba849a601438ae77eec9893',
]

type AddressMap = {
  [chainId: number]: string
}

export const RNG_ADDRESS = '0x3b94440c8c4f69d5c9f47bab9c5a93064df460f5'

const FEW_UNI_ADDRESSES: { [chainId: number]: string } = {
  [ChainId.GOERLI]: '0xd54a0A6269C236b3bB623282CA07a1D50e43c010',
  [ChainId.SEPOLIA]: '0xd54a0A6269C236b3bB623282CA07a1D50e43c010', // TODO, deploy the real address
}

export const FEW_UNI: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: new Token(ChainId.GOERLI, FEW_UNI_ADDRESSES[ChainId.GOERLI], 18, 'fwUNI', 'Few Wrapped Uniswap'),
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, FEW_UNI_ADDRESSES[ChainId.SEPOLIA], 18, 'fwUNI', 'Few Wrapped Uniswap'), // TODO, deploy the real address
}

export const FEW_DAI: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: FEW_DAI_GOERLI,
  [ChainId.BLAST_SEPOLIA]: FEW_DAI_BLAST_SEPOLIA,
  [ChainId.SEPOLIA]: FEW_DAI_SEPOLIA,
  [ChainId.MAINNET]: FEW_DAI_MAINNET,
  [ChainId.ARBITRUM_SEPOLIA]: FEW_DAI_ARBITRUM_SEPOLIA,
  [ChainId.BASE]: FEW_DAI_BASE,
  [ChainId.ARBITRUM_ONE]: FEW_DAI_ARBITRUM_ONE,
}

export const FEW_USDB: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_USDB_BLAST,
  [ChainId.BLAST_SEPOLIA]: FEW_USDB_BLAST_SEPOLIA,
}

export const USDB: { [chainId: number]: Token } = {
  [ChainId.BLAST]: USDB_BLAST,
  [ChainId.BLAST_SEPOLIA]: USDB_BLAST_SEPOLIA,
}

export const FEW_ETHX: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_ETHX_BLAST,
}

export const FEW_OMNI: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_OMNI_BLAST,
}

export const FEW_JUICE: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_JUICE_BLAST,
}

export const FEW_WBTC: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_WBTC_BLAST,
  [ChainId.ARBITRUM_ONE]: FEW_WBTC_ARBITRUM_ONE,
}

export const FEW_ORBIT: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_ORBIT_BLAST,
}

export const FEW_DETH: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_DETH_BLAST,
}

export const FEW_BLAST: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_BLAST_TOKEN_BLAST,
}

export const FEW_DUSD: { [chainId: number]: Token } = {
  [ChainId.BLAST]: FEW_DUSD_BLAST,
}

export const FEW_USDT: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: FEW_USDT_GOERLI,
  [ChainId.BLAST_SEPOLIA]: FEW_USDT_BLAST_SEPOLIA,
  [ChainId.SEPOLIA]: FEW_USDT_SEPOLIA,
  [ChainId.MAINNET]: FEW_USDT_MAINNET,
  [ChainId.BASE]: FEW_USDT_BASE,
  [ChainId.ARBITRUM_ONE]: FEW_USDT_ARBITRUM_ONE,
}

export const FEW_USDC: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: FEW_USDC_GOERLI,
  [ChainId.BLAST_SEPOLIA]: FEW_USDC_BLAST_SEPOLIA,
  [ChainId.SEPOLIA]: FEW_USDC_SEPOLIA,
  [ChainId.MAINNET]: FEW_USDC_MAINNET,
  [ChainId.BASE]: FEW_USDC_BASE,
  [ChainId.ARBITRUM_ONE]: FEW_USDC_ARBITRUM_ONE,
}

export const FEW_USDBC: { [chainId: number]: Token } = {
  [ChainId.BASE]: FEW_USDBC_BASE,
}

export const SWAP_INCENTIVE_ADDRESS: AddressMap = {
  [ChainId.BLAST]: '0x4610A79b9D0D075D7d63D112018A49059529c503',
  [ChainId.BLAST_SEPOLIA]: '0xC3C7A755870D076F32E9Ff5b79c267E312b14CC5',
}

export const FEW_RNG: { [chainId: number]: Token } = {
  [ChainId.GOERLI]: FEW_RNG_GOERLI,
  [ChainId.BLAST_SEPOLIA]: FEW_RNG_BLAST_SEPOLIA,
  [ChainId.BLAST]: FEW_RNG_BLAST,
  [ChainId.SEPOLIA]: FEW_RNG_SEPOLIA,
}

export const FEW_RGB: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: FEW_RGB_BLAST_SEPOLIA,
}

export const FEW_RING: { [chainId: number]: Token } = {
  [ChainId.BLAST_SEPOLIA]: FEW_RING_BLAST_SEPOLIA,
  [ChainId.BLAST]: FEW_RING_BLAST,
  [ChainId.SEPOLIA]: FEW_RING_SEPOLIA,
}

export const SWAPNET_PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'
export const SWAPNET_UNIVERSAL_ROUTER_ADDRESS = '0x2C8754B44865002415dD5CEBa6Cd67258D1eCe2e'

const FEW_SEPOLIA_V2_ROUTER_ADDRESS = '0xDEFD930ca2b0ecb81699B635D3d3dE226445db57'
const FEW_BLAST_SEPOLIA_V2_ROUTER_ADDRESS = '0x02F1e7A518e3E286C8E305E39cA7D4f25e0a44Aa'
const FEW_BLAST_V2_ROUTER_ADDRESS = '0x7001F706ACB6440d17cBFaD63Fa50a22D51696fF'
const FEW_ETH_V2_ROUTER_ADDRESS = '0x39d1d8fcC5E6EEAf567Bce4e29B94fec956D3519' // replace the real swap v2 router contract address
const FEW_ARB_SEPOLIA_V2_ROUTER_ADDRESS = '0xCe8eA134624D439489f8a781e7750db533C0920f'
const FEW_BASE_V2_ROUTER_ADDRESS = '0x224749CDd5791480ECEBE452e5FFAEfEf94Cd254'
const FEW_ARBITRUM_V2_ROUTER_ADDRESS = '0xD69ed581480239357515e200560353AF1BbaA46A'

export const V2_ROUTER_ADDRESSES: AddressMap = {
  [11155111]: FEW_SEPOLIA_V2_ROUTER_ADDRESS,
  [168587773]: FEW_BLAST_SEPOLIA_V2_ROUTER_ADDRESS,
  [81457]: FEW_BLAST_V2_ROUTER_ADDRESS,
  [1]: FEW_ETH_V2_ROUTER_ADDRESS,
  [421614]: FEW_ARB_SEPOLIA_V2_ROUTER_ADDRESS,
  [8453]: FEW_BASE_V2_ROUTER_ADDRESS,
  [42161]: FEW_ARBITRUM_V2_ROUTER_ADDRESS,
}

const SEPOLIA_MEME_LAUNCHA_PAD_ADDRESS = '0xB9c1f88B0DF74102613BC935a90A4699C74be124'
const ARBITRUM_SEPOLIA_MEME_LAUNCHA_PAD_ADDRESS = '0x43359013a8393D48782C14C297cdb834620509BB'
const BASE_MEME_LAUNCHA_PAD_ADDRESS = '0x833d4795fab8F030C790C8286F15553437c0DA30'
const ETH_MEME_LAUNCHA_PAD_ADDRESS = '0x8814a2AA2384d65672C7dC0650454E56F62fBA8B'
const BLAST_MEME_LAUNCHA_PAD_ADDRESS = '0x8814a2AA2384d65672C7dC0650454E56F62fBA8B'
const ARBITRUM_MEME_LAUNCHA_PAD_ADDRESS = '0x4De602A30Ad7fEf8223dcf67A9fB704324C4dd9B'

export const MEME_LAUNCHA_PAD_ADDRESSES: AddressMap = {
  [11155111]: SEPOLIA_MEME_LAUNCHA_PAD_ADDRESS,
  [81457]: BLAST_MEME_LAUNCHA_PAD_ADDRESS,
  [1]: ETH_MEME_LAUNCHA_PAD_ADDRESS,
  [421614]: ARBITRUM_SEPOLIA_MEME_LAUNCHA_PAD_ADDRESS,
  [8453]: BASE_MEME_LAUNCHA_PAD_ADDRESS,
  [42161]: ARBITRUM_MEME_LAUNCHA_PAD_ADDRESS,
}

const BLAST_SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS = '0x9314198EE2aBd28a094ba4433ADD81BE001ecAa3'
const BLAST_FEW_TOKEN_ROUTER_ADDRESS = '0xa9F8C9a199a36cF428Dd1Ccb0D81D51BCe844220'
const ETH_FEW_TOKEN_ROUTER_ADDRESS = '0x6A7c92Bddb8D695cfDFf5D47487A456Cc46e0ee5' // replace the real fewtoekn router contract address
const SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS = '0x385157B1939565D0F18dF2086F50708b42514f0f'
const ARBITRUM_SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS = '0xB86351A162F10a6DB3D2A7aA54d1D722eBC4A057'
const ARBITRUM_MAINNET_FEW_TOKEN_ROUTER_ADDRESS = '0xf9d7ff2f6A0c3631A807199276a493Af8097916F'
const BASE_MAINNET_FEW_TOKEN_ROUTER_ADDRESS = '0xf293096D3C33610461a7bd101D201683111749b8'

export const FEW_TOKEN_ROUTER_ADDRESSES: AddressMap = {
  [168587773]: BLAST_SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS,
  [81457]: BLAST_FEW_TOKEN_ROUTER_ADDRESS,
  [11155111]: SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS,
  [1]: ETH_FEW_TOKEN_ROUTER_ADDRESS,
  [421614]: ARBITRUM_SEPOLIA_FEW_TOKEN_ROUTER_ADDRESS,
  [8453]: BASE_MAINNET_FEW_TOKEN_ROUTER_ADDRESS,
  [42161]: ARBITRUM_MAINNET_FEW_TOKEN_ROUTER_ADDRESS,
}

export const RING_V2_ROUTER_ADDRESSES: AddressMap = {
  [11155111]: FEW_SEPOLIA_V2_ROUTER_ADDRESS,
  [168587773]: FEW_BLAST_SEPOLIA_V2_ROUTER_ADDRESS,
  [81457]: FEW_BLAST_V2_ROUTER_ADDRESS,
}

export const V3_FACTORY_ADDRESSES: AddressMap = {
  [11155111]: '0x0227628f3F023bb0B980b67D528571c95c6DaC1c',
  [168587773]: '0x3C987A63a69Ba9D29b5aC2f5980870F7fd2b50cC',
  [81457]: '0x890509Fab3dD11D4Ff57d8471b5eAC74687E4C75',
  [421614]: '0x04A5A539988125aA1bD6D09242D916882C35Aab4',
}

export const FEW_ETH_WRAPPER_ADDRESSES: AddressMap = {
  [11155111]: '0xC8D2bBBfDf6CED270d82aEa5961fE28608490F9f',
  [168587773]: '0xCd31E6C1AD4c9E0E7F6A1f44583C038eaAaCf53F',
  [81457]: '0xF272a4b0d949011f9347134088126277abeB065F',
  [1]: '0xAda6059b4F6244Acd8934095Ed0162C5Df6B5ebB',
  [421614]: '0x295D7CF11f6667C359563A7FFAC54106DD6f114b',
  [8453]: '0x20E6B1260d12910C0Ab13c1AbEBCFe24AE9c4fe7',
  [42161]: '0xEeE400Eabfba8F60f4e6B351D8577394BeB972CD',
}

export const V3_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [11155111]: '0x353c7D39ee36b557F84A0617580914Aab9C37aEd',
  [168587773]: '0x795367F05135ae4E6c49F5C3671571a193ee3450',
  [81457]: '0xACcBa426E72E14bfdAdbF7Ce752F92CC05ab4949',
  [421614]: '0x920F762DFa35eCB535A0D48BA0428F9ab466260D',
}

// help user withdraw token from old pool
export const V3_01_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [11155111]: '0x468E8D27B83E45DEf94bF1d08c19A4ebacEE4480',
  [168587773]: '0x795367F05135ae4E6c49F5C3671571a193ee3450',
  [81457]: '0x44d8a7885C48C20861B49cD238E612056223D995',
}

// export const FEW_WRAPPED_TOKEN_FACTORY = '0xf11788d14EbE6abF4EA02e162C75AD938F1730C1'
export const RING_V2_FACTORY_ADDRESSES: AddressMap = {
  [1]: '0xeb2A625B704d73e82946D8d026E1F588Eed06416',
  [11155111]: '0x3D7101fDe92D0961cAc4b39Ee5A638d7B6A88090',
  [168587773]: '0xe4aa82591AD0E8AbFD15ca2A2ff74439c6052Fbf',
  [81457]: '0x24F5Ac9A706De0cF795A8193F6AB3966B14ECfE6',
  [421614]: '0x13429d9780662560F526Ba0DA751C191DFdF1849',
  [42161]: '0x1246Fa62467a9AC0892a2d2A9F9aafC2F5609442',
  [8453]: '0x9BfFC3B30D6659e3D84754cc38865B3D60B4980E',
}
export const UNI_V2_FACTORY_ADDRESSES: AddressMap = {
  [1]: '0xeb2A625B704d73e82946D8d026E1F588Eed06416',
  [11155111]: '0x3D7101fDe92D0961cAc4b39Ee5A638d7B6A88090',
  [168587773]: '0xe4aa82591AD0E8AbFD15ca2A2ff74439c6052Fbf',
  [81457]: '0x24F5Ac9A706De0cF795A8193F6AB3966B14ECfE6',
  [421614]: '0x13429d9780662560F526Ba0DA751C191DFdF1849',
  [42161]: '0x1246Fa62467a9AC0892a2d2A9F9aafC2F5609442',
  [8453]: '0x9BfFC3B30D6659e3D84754cc38865B3D60B4980E',
}

export const TOKEN_CONVERTER_ADDRESS: AddressMap = {
  [11155111]: '0x360965cC84740266433543CC51De0D2291293952',
  [168587773]: '0x212a1BB2BcCf908e7D9276Ed2F242D3AFfADC4Ac',
  [81457]: '0x3337583D01578Ff8a27dE0B5D34fe8E361eaB5aF',
}

export const V3_STAKER_ADDRESSES: AddressMap = constructSameAddressMap('0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d')

export const EARN_BLAST_GOLD_SUPPORT_TOKEN: string[] = [RING[ChainId.BLAST].address]

export function isEarnBlastGoldSupportedToken(tokenAddress: string | null | undefined): boolean {
  return !!tokenAddress && EARN_BLAST_GOLD_SUPPORT_TOKEN.indexOf(tokenAddress) !== -1
}
