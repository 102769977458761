import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import KyberLogo from 'assets/svg/dapp/kyber_logo.svg'
import { RowBetween } from 'components/Row'
import { isSupportedChain } from 'constants/chains'
import { ArrowUpRight } from 'react-feather'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HideSmall, ThemedText } from 'theme'

import Column from '../Column'

const TitleText = styled(ThemedText.BodyPrimary)<{ $color: string }>`
  font-weight: 535;
  color: ${({ $color }) => $color};
`

const SubtitleText = styled(ThemedText.BodySmall)<{ $color: string }>`
  line-height: 20px;
  color: ${({ $color }) => $color};
`

export function KyberswapAlert() {
  const { chainId } = useWeb3React()
  if (!chainId || !isSupportedChain(chainId)) return null

  return (
    <div className="bg-[#31cb9e] rounded-[20px] p-3 pr-[18px] mt-4">
      <Link to="/kyber" className="flex items-center">
        <img src={KyberLogo} width={32} height={32} className="mr-4 ml-1" />
        <RowBetween>
          <Column>
            <TitleText $color="#000000">
              <Trans>Kyberswap</Trans>
            </TitleText>
            <HideSmall>
              <SubtitleText $color="#000000">
                <Trans>KyberSwap is a multi-chain aggregator.</Trans>
              </SubtitleText>
            </HideSmall>
          </Column>
          <div className="flex">
            <TitleText $color="#000000">
              <Trans>Kyber</Trans>
            </TitleText>
            <ArrowUpRight width="24px" height="24px" color="#000000" />
          </div>
        </RowBetween>
      </Link>
    </div>
  )
}
