import { ChainId } from 'few-sdk-core-multiple-network-2'

export const API_KEYS: { [key in ChainId]: string } = {
  [ChainId.BLAST]: 'a0e880ef',
  [ChainId.MAINNET]: '',
  [ChainId.GOERLI]: '',
  [ChainId.SEPOLIA]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.OPTIMISM_GOERLI]: '',
  [ChainId.ARBITRUM_ONE]: '',
  [ChainId.ARBITRUM_GOERLI]: '',
  [ChainId.POLYGON]: '',
  [ChainId.POLYGON_MUMBAI]: '',
  [ChainId.CELO]: '',
  [ChainId.CELO_ALFAJORES]: '',
  [ChainId.GNOSIS]: '',
  [ChainId.MOONBEAM]: '',
  [ChainId.BNB]: '',
  [ChainId.AVALANCHE]: '',
  [ChainId.BASE_GOERLI]: '',
  [ChainId.BASE]: '',
  [ChainId.BLAST_SEPOLIA]: '',
  [ChainId.UNICHAIN]: '',
  [ChainId.UNICHAIN_SEPOLIA]: '',
  [ChainId.ARBITRUM_SEPOLIA]: '',
}
