import { createReducer } from '@reduxjs/toolkit'

import { Field, resetMintState, typeInput } from './actions'

export interface MintState {
  readonly nameField: Field
  readonly symbolField: Field
  readonly totalSupplyField: Field
  readonly pairAmountField: Field
  readonly buyMemeField: Field
  readonly nameTypedValue: string
  readonly symbolTypedValue: string
  readonly totalSupplyTypedValue: string
  readonly pairAmountTypedValue: string
  readonly buyTokenTypedValue: string
}

export const initialState: MintState = {
  nameField: Field.NAME,
  symbolField: Field.SYMBOL,
  totalSupplyField: Field.TOTAL_SUPPLY_AMOUNT,
  pairAmountField: Field.PAIR_AMOUNT,
  buyMemeField: Field.BUY_MEME_CURRENCY,
  nameTypedValue: '',
  symbolTypedValue: '',
  totalSupplyTypedValue: '',
  pairAmountTypedValue: '',
  buyTokenTypedValue: '',
}

export default createReducer<MintState>(initialState, (builder) =>
  builder
    .addCase(resetMintState, () => initialState)
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      if (field === state.nameField) {
        return {
          ...state,
          independentField: Field.NAME,
          nameTypedValue: typedValue,
          symbolTypedValue: state.symbolTypedValue,
          totalSupplyTypedValue: state.totalSupplyTypedValue,
          pairAmountTypedValue: state.pairAmountTypedValue,
          buyTokenTypedValue: state.buyTokenTypedValue,
        }
      } else if (field === state.symbolField) {
        return {
          ...state,
          independentField: Field.SYMBOL,
          nameTypedValue: state.nameTypedValue,
          symbolTypedValue: typedValue,
          totalSupplyTypedValue: state.totalSupplyTypedValue,
          pairAmountTypedValue: state.pairAmountTypedValue,
          buyTokenTypedValue: state.buyTokenTypedValue,
        }
      } else if (field === state.totalSupplyField) {
        return {
          ...state,
          independentField: Field.TOTAL_SUPPLY_AMOUNT,
          nameTypedValue: state.nameTypedValue,
          symbolTypedValue: state.symbolTypedValue,
          totalSupplyTypedValue: typedValue,
          pairAmountTypedValue: state.pairAmountTypedValue,
          buyTokenTypedValue: state.buyTokenTypedValue,
        }
      } else if (field === state.pairAmountField) {
        return {
          ...state,
          independentField: Field.PAIR_AMOUNT,
          nameTypedValue: state.nameTypedValue,
          symbolTypedValue: state.symbolTypedValue,
          totalSupplyTypedValue: state.totalSupplyTypedValue,
          pairAmountTypedValue: typedValue,
          buyTokenTypedValue: state.buyTokenTypedValue,
        }
      } else if (field === state.buyMemeField) {
        return {
          ...state,
          independentField: Field.BUY_MEME_CURRENCY,
          nameTypedValue: state.nameTypedValue,
          symbolTypedValue: state.symbolTypedValue,
          totalSupplyTypedValue: state.totalSupplyTypedValue,
          pairAmountTypedValue: state.pairAmountTypedValue,
          buyTokenTypedValue: typedValue,
        }
      } else {
        return {
          ...state,
          independentField: field,
          nameTypedValue: state.nameTypedValue,
          symbolTypedValue: state.symbolTypedValue,
          totalSupplyTypedValue: state.totalSupplyTypedValue,
          pairAmountTypedValue: state.pairAmountTypedValue,
          buyTokenTypedValue: typedValue,
        }
      }
    })
)
