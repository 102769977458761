/* eslint-disable import/no-unused-modules */
import { useWeb3React } from '@web3-react/core'
import { L2_CHAIN_IDS } from 'constants/chains'
import { SupportedLocale } from 'constants/locales'
import { L2_DEADLINE_FROM_NOW } from 'constants/misc'
import { RING_V2_FACTORY_ADDRESSES, UNI_V2_FACTORY_ADDRESSES } from 'constants/tokens'
import { Percent, Token } from 'few-sdk-core-multiple-network-2'
import {
  computePairAddress as computeFewPairAddress,
  INIT_CODE_HASH_MAP as FEW_INIT_CODE_HASH_MAP,
  Pair as FewPair,
} from 'few-v2-sdk-multiple-network-4'
import {
  computePairAddress as computeRingPairAddress,
  INIT_CODE_HASH_MAP as RING_INIT_CODE_HASH_MAP,
  Pair as RingPair,
} from 'few-v2-sdk-multiple-network-4'
import { useNetworkSupportsV2 } from 'hooks/useNetworkSupportsV2'
import JSBI from 'jsbi'
import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { RouterPreference } from 'state/routing/types'
import { UserAddedToken, UserAddedWrappedToken } from 'types/tokens'

import {
  FEW_BASES_TO_TRACK_LIQUIDITY_FOR,
  FEW_WRAPPED_PINNED_PAIRS,
  RING_WRAPPED_PINNED_PAIRS,
} from '../../constants/routing'
import { useDefaultFewWrappedActiveTokens, useDefaultRingWrappedActiveTokens } from '../../hooks/Tokens'
import {
  addSerializedFewPair,
  addSerializedRingPair,
  addSerializedToken,
  addSerializedWrappedToken,
  updateHideAppPromoBanner,
  updateHideClosedPositions,
  updateUserDeadline,
  updateUserLocale,
  updateUserRouterPreference,
  updateUserSlippageTolerance,
} from './reducer'
import { SerializedPair, SerializedRingPair, SerializedToken, SlippageTolerance } from './types'

export function serializeToken(token: Token): SerializedToken {
  return {
    chainId: token.chainId,
    address: token.address,
    decimals: token.decimals,
    symbol: token.symbol,
    name: token.name,
  }
}

export function deserializeToken(serializedToken: SerializedToken, Class: typeof Token = Token): Token {
  return new Class(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name
  )
}

export function useUserLocale(): SupportedLocale | null {
  return useAppSelector((state) => state.user.userLocale)
}

export function useUserLocaleManager(): [SupportedLocale | null, (newLocale: SupportedLocale) => void] {
  const dispatch = useAppDispatch()
  const locale = useUserLocale()

  const setLocale = useCallback(
    (newLocale: SupportedLocale) => {
      dispatch(updateUserLocale({ userLocale: newLocale }))
    },
    [dispatch]
  )

  return [locale, setLocale]
}

export function useRouterPreference(): [RouterPreference, (routerPreference: RouterPreference) => void] {
  const dispatch = useAppDispatch()

  const routerPreference = useAppSelector((state) => state.user.userRouterPreference)

  const setRouterPreference = useCallback(
    (newRouterPreference: RouterPreference) => {
      dispatch(updateUserRouterPreference({ userRouterPreference: newRouterPreference }))
    },
    [dispatch]
  )

  return [routerPreference, setRouterPreference]
}

/**
 * Return the user's slippage tolerance, from the redux store, and a function to update the slippage tolerance
 */
export function useUserSlippageTolerance(): [
  Percent | SlippageTolerance.Auto,
  (slippageTolerance: Percent | SlippageTolerance.Auto) => void
] {
  const userSlippageToleranceRaw = useAppSelector((state) => {
    return state.user.userSlippageTolerance
  })

  // TODO(WEB-1985): Keep `userSlippageTolerance` as Percent in Redux store and remove this conversion
  const userSlippageTolerance = useMemo(
    () =>
      userSlippageToleranceRaw === SlippageTolerance.Auto
        ? SlippageTolerance.Auto
        : new Percent(userSlippageToleranceRaw, 10_000),
    [userSlippageToleranceRaw]
  )

  const dispatch = useAppDispatch()
  const setUserSlippageTolerance = useCallback(
    (userSlippageTolerance: Percent | SlippageTolerance.Auto) => {
      let value: SlippageTolerance.Auto | number
      try {
        value =
          userSlippageTolerance === SlippageTolerance.Auto
            ? SlippageTolerance.Auto
            : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient)
      } catch (error) {
        value = SlippageTolerance.Auto
      }
      dispatch(
        updateUserSlippageTolerance({
          userSlippageTolerance: value,
        })
      )
    },
    [dispatch]
  )

  return [userSlippageTolerance, setUserSlippageTolerance]
}

/**
 *Returns user slippage tolerance, replacing the auto with a default value
 * @param defaultSlippageTolerance the value to replace auto with
 */
export function useUserSlippageToleranceWithDefault(defaultSlippageTolerance: Percent): Percent {
  const [allowedSlippage] = useUserSlippageTolerance()
  return allowedSlippage === SlippageTolerance.Auto ? defaultSlippageTolerance : allowedSlippage
}

export function useUserHideClosedPositions(): [boolean, (newHideClosedPositions: boolean) => void] {
  const dispatch = useAppDispatch()

  const hideClosedPositions = useAppSelector((state) => state.user.userHideClosedPositions)

  const setHideClosedPositions = useCallback(
    (newHideClosedPositions: boolean) => {
      dispatch(updateHideClosedPositions({ userHideClosedPositions: newHideClosedPositions }))
    },
    [dispatch]
  )

  return [hideClosedPositions, setHideClosedPositions]
}

export function useUserTransactionTTL(): [number, (slippage: number) => void] {
  const { chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const userDeadline = useAppSelector((state) => state.user.userDeadline)
  const onL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId))
  const deadline = onL2 ? L2_DEADLINE_FROM_NOW : userDeadline

  const setUserDeadline = useCallback(
    (userDeadline: number) => {
      dispatch(updateUserDeadline({ userDeadline }))
    },
    [dispatch]
  )

  return [deadline, setUserDeadline]
}

export function useAddUserToken(): (token: Token) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }))
    },
    [dispatch]
  )
}

export function useAddUserWrappedToken(): (token: Token) => void {
  const dispatch = useAppDispatch()
  return useCallback(
    (token: Token) => {
      dispatch(addSerializedWrappedToken({ serializedToken: serializeToken(token) }))
    },
    [dispatch]
  )
}

function useUserAddedTokensOnChain(chainId: number | undefined | null): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { tokens } }) => tokens)

  return useMemo(() => {
    if (!chainId) return []
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map((value) => deserializeToken(value, UserAddedToken))
      : []
    return tokenMap
  }, [serializedTokensMap, chainId])
}

function useUserAddedWrappedTokensOnChain(chainId: number | undefined | null): Token[] {
  const serializedTokensMap = useAppSelector(({ user: { wrappedTokens } }) => wrappedTokens)
  return useMemo(() => {
    if (!chainId) return []
    const tokenMap: Token[] = serializedTokensMap?.[chainId]
      ? Object.values(serializedTokensMap[chainId]).map((value) => deserializeToken(value, UserAddedWrappedToken))
      : []
    return tokenMap
  }, [serializedTokensMap, chainId])
}

export function useUserAddedTokens(): Token[] {
  return useUserAddedTokensOnChain(useWeb3React().chainId)
}

export function useUserAddedWrappedTokens(): Token[] {
  return useUserAddedWrappedTokensOnChain(useWeb3React().chainId)
}

function serializePair(pair: FewPair): SerializedPair {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1),
  }
}

function serializeRingPair(pair: RingPair): SerializedRingPair {
  return {
    token0: serializeToken(pair.token0),
    token1: serializeToken(pair.token1),
  }
}

export function useRingPairAdder(): (pair: RingPair) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (pair: RingPair) => {
      dispatch(addSerializedRingPair({ serializedPair: serializeRingPair(pair) }))
    },
    [dispatch]
  )
}

export function useFewPairAdder(): (pair: FewPair) => void {
  const dispatch = useAppDispatch()

  return useCallback(
    (pair: FewPair) => {
      dispatch(addSerializedFewPair({ serializedPair: serializePair(pair) }))
    },
    [dispatch]
  )
}

export function useHideAppPromoBanner(): [boolean, () => void] {
  const dispatch = useAppDispatch()
  const hideAppPromoBanner = useAppSelector((state) => state.user.hideAppPromoBanner)

  const toggleHideAppPromoBanner = useCallback(() => {
    dispatch(updateHideAppPromoBanner({ hideAppPromoBanner: true }))
  }, [dispatch])

  return [hideAppPromoBanner, toggleHideAppPromoBanner]
}

// export function useHideBaseWalletBanner(): [boolean, () => void] {
//   const dispatch = useAppDispatch()
//   const hideBaseWalletBanner = useAppSelector((state) => state.user.hideBaseWalletBanner)

//   const toggleHideBaseWalletBanner = useCallback(() => {
//     dispatch(updateHideBaseWalletBanner({ hideBaseWalletBanner: true }))
//   }, [dispatch])

//   return [hideBaseWalletBanner, toggleHideBaseWalletBanner]
// }

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  if (tokenA.chainId !== tokenB.chainId) throw new Error('Not matching chain IDs')
  if (tokenA.equals(tokenB)) throw new Error('Tokens cannot be equal')
  if (!UNI_V2_FACTORY_ADDRESSES[tokenA.chainId]) throw new Error('No V2 factory address on this chain')

  return new Token(
    tokenA.chainId,
    computeFewPairAddress({
      initCodeHash: FEW_INIT_CODE_HASH_MAP[tokenA.chainId],
      factoryAddress: UNI_V2_FACTORY_ADDRESSES[tokenA.chainId],
      tokenA,
      tokenB,
    }),
    18,
    'RING-V2',
    'RingSwap V2'
  )
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toRingLiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
  if (tokenA.chainId !== tokenB.chainId) throw new Error('Not matching chain IDs')
  if (tokenA.equals(tokenB)) throw new Error('Tokens cannot be equal')
  if (!RING_V2_FACTORY_ADDRESSES[tokenA.chainId]) throw new Error('No V2 factory address on this chain')

  return new Token(
    tokenA.chainId,
    computeRingPairAddress({
      initCodeHash: RING_INIT_CODE_HASH_MAP[tokenA.chainId],
      factoryAddress: RING_V2_FACTORY_ADDRESSES[tokenA.chainId],
      tokenA,
      tokenB,
    }),
    18,
    'RING-V2',
    'RingSwap V2'
  )
}

/**
 * Returns all the few pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useFewTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useWeb3React()
  const tokens = useDefaultFewWrappedActiveTokens(chainId)
  const pinnedPairs = useMemo(() => (chainId ? FEW_WRAPPED_PINNED_PAIRS[chainId] ?? [] : []), [chainId])

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? Object.keys(tokens).flatMap((tokenAddress) => {
            const token = tokens[tokenAddress]
            // for each token on the current chain,
            return (
              // loop though all bases on the current chain
              (FEW_BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null
                  } else {
                    return [base, token]
                  }
                })
                .filter((p): p is [Token, Token] => p !== null)
            )
          })
        : [],
    [tokens, chainId]
  )

  // pairs saved by users
  const savedSerializedFewPairs = useAppSelector(({ user: { fewPairs } }) => fewPairs)

  const userFewPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedFewPairs) return []
    const forChain = savedSerializedFewPairs[chainId]
    if (!forChain) return []

    return Object.keys(forChain).map((pairId) => {
      return [deserializeToken(forChain[pairId].token0), deserializeToken(forChain[pairId].token1)]
    })
  }, [savedSerializedFewPairs, chainId])

  const combinedList = useMemo(
    () => userFewPairs.concat(generatedPairs).concat(pinnedPairs),
    [generatedPairs, pinnedPairs, userFewPairs]
  )

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>((memo, [tokenA, tokenB]) => {
      const sorted = tokenA.sortsBefore(tokenB)
      const key = sorted ? `${tokenA.address}:${tokenB.address}` : `${tokenB.address}:${tokenA.address}`
      if (memo[key]) return memo
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA]
      return memo
    }, {})

    return Object.keys(keyed).map((key) => keyed[key])
  }, [combinedList])
}

/**
 * Returns all the few pairs of tokens that are tracked by the user for the current chain ID.
 */
export function useRingTrackedTokenPairs(): [Token, Token][] {
  const { chainId } = useWeb3React()
  const tokens = useDefaultRingWrappedActiveTokens(chainId)
  const pinnedPairs = useMemo(() => (chainId ? RING_WRAPPED_PINNED_PAIRS[chainId] ?? [] : []), [chainId])

  // pairs for every token against every base
  const generatedPairs: [Token, Token][] = useMemo(
    () =>
      chainId
        ? Object.keys(tokens).flatMap((tokenAddress) => {
            const token = tokens[tokenAddress]
            // for each token on the current chain,
            return (
              // loop though all bases on the current chain
              (FEW_BASES_TO_TRACK_LIQUIDITY_FOR[chainId] ?? [])
                // to construct pairs of the given token with each base
                .map((base) => {
                  if (base.address === token.address) {
                    return null
                  } else {
                    return [base, token]
                  }
                })
                .filter((p): p is [Token, Token] => p !== null)
            )
          })
        : [],
    [tokens, chainId]
  )

  // pairs saved by users
  const savedSerializedRingPairs = useAppSelector(({ user: { ringPairs } }) => ringPairs)

  const userRingPairs: [Token, Token][] = useMemo(() => {
    if (!chainId || !savedSerializedRingPairs) return []
    const forChain = savedSerializedRingPairs[chainId]
    if (!forChain) return []

    return Object.keys(forChain).map((pairId) => {
      return [deserializeToken(forChain[pairId].token0), deserializeToken(forChain[pairId].token1)]
    })
  }, [savedSerializedRingPairs, chainId])

  const combinedList = useMemo(
    () => userRingPairs.concat(generatedPairs).concat(pinnedPairs),
    [generatedPairs, pinnedPairs, userRingPairs]
  )

  return useMemo(() => {
    // dedupes pairs of tokens in the combined list
    const keyed = combinedList.reduce<{ [key: string]: [Token, Token] }>((memo, [tokenA, tokenB]) => {
      const sorted = tokenA.sortsBefore(tokenB)
      const key = sorted ? `${tokenA.address}:${tokenB.address}` : `${tokenB.address}:${tokenA.address}`
      if (memo[key]) return memo
      memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA]
      return memo
    }, {})

    return Object.keys(keyed).map((key) => keyed[key])
  }, [combinedList])
}

export function useAllLiquidityToken() {
  const networkSupportsV2 = useNetworkSupportsV2()

  // fetch the user's balances of all tracked V2 LP tokens
  let fewTrackedTokenPairs = useFewTrackedTokenPairs()

  if (!networkSupportsV2) fewTrackedTokenPairs = []
  const tokenPairsWithLiquidityTokens = useMemo(
    () => fewTrackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [fewTrackedTokenPairs]
  )

  return useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
}

export function useCheckLiquidityToken(address: string): boolean {
  const liquidityTokens = useAllLiquidityToken()

  return useMemo(() => liquidityTokens.some((token) => token.address === address), [address, liquidityTokens])
}
