import { Trans } from '@lingui/macro'
import { Currency, Percent } from 'few-sdk-core-multiple-network-2'
import { InterfaceTrade } from 'state/routing/types'
import styled from 'styled-components'
import { ThemedText } from 'theme'

import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
// import SwapBuyFiatButton from './SwapBuyFiatButton'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.neutral2};
`

const HeaderButtonContainer = styled(RowFixed)`
  padding: 0 12px;
  gap: 16px;
`

export default function SwapHeader({
  autoSlippage,
  chainId,
}: {
  autoSlippage: Percent
  chainId?: number
  trade?: InterfaceTrade
  currencies?: {
    INPUT?: Currency | null
    OUTPUT?: Currency | null
  }
}) {
  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeader>
          <Trans>Wrap</Trans>
        </ThemedText.SubHeader>
        {/* <SwapBuyFiatButton /> */}
      </HeaderButtonContainer>
      <RowFixed>
        {/* <MinerTab chainId={chainId} currencies={currencies} /> */}
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
