import { Currency, Token } from 'few-sdk-core-multiple-network-2'
import { isFewToken, useOriginalTokenOfFewToken } from 'hooks/useWrappedToken'

import AssetLogo, { AssetLogoBaseProps } from './AssetLogo'

export default function CurrencyLogo(
  props: AssetLogoBaseProps & {
    currency?: Currency | null
  }
) {
  const fewToken = isFewToken(props.currency as Token)
  const originalToken = useOriginalTokenOfFewToken(props.currency as Token)

  return fewToken ? (
    <AssetLogo
      isNative={originalToken?.isNative}
      chainId={originalToken?.chainId}
      address={originalToken?.wrapped.address}
      symbol={props.symbol ?? props.currency?.symbol}
      {...props}
    />
  ) : (
    <AssetLogo
      isNative={props.currency?.isNative}
      chainId={props.currency?.chainId}
      address={props.currency?.wrapped.address}
      symbol={props.symbol ?? props.currency?.symbol}
      {...props}
    />
  )
}
