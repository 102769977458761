import { useWeb3React } from '@web3-react/core'
import { FEW_USDB, FEW_WRAPPED_NATIVE_CURRENCY, FWWETH_FWUSDB_PAIR_BLAST, USDB } from 'constants/tokens'
import { ChainId, CurrencyAmount, Token, WETH9 } from 'few-sdk-core-multiple-network-2'
import { Pair } from 'few-v2-sdk-multiple-network-4'
import JSBI from 'jsbi'
import { useMemo } from 'react'
import { useNewStakingInfo, useNewStakingTokenInfo } from 'state/stake/hooks'

import { useAllTotalSupply } from './useTotalSupply'
import { useV2Pair, useV2Pairs } from './useV2Pairs'

export function useEarnTvl() {
  const { chainId } = useWeb3React()
  const newStakingInfos = useNewStakingInfo()
  const newStakingTokenInfos = useNewStakingTokenInfo()
  const totalSupplyOfStakingTokens = useAllTotalSupply(newStakingInfos)

  // calculate tvl
  const fwETH = FEW_WRAPPED_NATIVE_CURRENCY[chainId ?? ChainId.BLAST]
  const [, stakingSingTokenPair] = useV2Pair(
    ...(newStakingInfos.find((i) => i.totalStakedAmount.currency.address === FWWETH_FWUSDB_PAIR_BLAST.address)
      ?.tokens ?? [])
  )
  const pairs = useV2Pairs(newStakingInfos.map((info) => info.tokens))
  const stakingTokenPairs = pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const WETH = WETH9[chainId ?? ChainId.BLAST]
  const ethPrice: string | undefined = stakingTokenPairs
    .find((i) => i.liquidityToken.address === FWWETH_FWUSDB_PAIR_BLAST.address)
    ?.token0Price.toSignificant(6)

  return useMemo(() => {
    let poolsTvl = 0
    let singPoolsTvl = 0
    if (newStakingInfos && newStakingInfos.length) {
      newStakingInfos.map((stakingInfo, index) => {
        const totalSupplyOfStakingToken = totalSupplyOfStakingTokens[index]
        const stakingTokenPair = stakingTokenPairs[index]

        const fwETH = FEW_WRAPPED_NATIVE_CURRENCY[chainId ?? ChainId.BLAST]
        const fwUSDB = FEW_USDB[chainId ?? ChainId.BLAST]
        const usdb = USDB[chainId ?? ChainId.BLAST]

        // get WETH value of staked LP tokens
        let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined
        // get USDB value of staked LP tokens
        let valueOfTotalStakedAmountInUSDB: CurrencyAmount<Token> | undefined

        if (stakingInfo.tokens.includes(fwETH)) {
          if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && fwETH) {
            // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
            valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
              WETH,
              JSBI.divide(
                JSBI.multiply(
                  JSBI.multiply(stakingInfo.totalStakedAmount.quotient, stakingTokenPair.reserveOf(fwETH).quotient),
                  JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
                ),
                totalSupplyOfStakingToken.quotient
              )
            )
          }
        } else if (stakingInfo.tokens.includes(fwUSDB)) {
          if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && usdb && fwUSDB) {
            // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
            valueOfTotalStakedAmountInUSDB = CurrencyAmount.fromRawAmount(
              usdb,
              JSBI.divide(
                JSBI.multiply(
                  JSBI.multiply(stakingInfo.totalStakedAmount.quotient, stakingTokenPair.reserveOf(fwUSDB).quotient),
                  JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
                ),
                totalSupplyOfStakingToken.quotient
              )
            )
          }
        } else {
          valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(WETH, 0)
          valueOfTotalStakedAmountInUSDB = CurrencyAmount.fromRawAmount(usdb, 0)
        }

        const valueOfTotalStakedAmountInUSDC =
          valueOfTotalStakedAmountInWETH && Number(valueOfTotalStakedAmountInWETH.toFixed(4)) * Number(ethPrice)

        const usdbValueOfTotalStakedAmountInUSDC =
          valueOfTotalStakedAmountInUSDB && Number(valueOfTotalStakedAmountInUSDB.toFixed(4))

        poolsTvl = valueOfTotalStakedAmountInUSDC
          ? Number(valueOfTotalStakedAmountInUSDC.toFixed(0)) + poolsTvl
          : usdbValueOfTotalStakedAmountInUSDC
          ? Number(usdbValueOfTotalStakedAmountInUSDC?.toFixed(0)) + poolsTvl
          : poolsTvl

        return valueOfTotalStakedAmountInUSDC
          ? Number(valueOfTotalStakedAmountInUSDC.toFixed(0))
          : usdbValueOfTotalStakedAmountInUSDC
          ? Number(usdbValueOfTotalStakedAmountInUSDC?.toFixed(0))
          : 0
      })

      if (newStakingTokenInfos.length && newStakingInfos.length && ethPrice && stakingSingTokenPair && fwETH) {
        singPoolsTvl =
          (Number(newStakingTokenInfos[0]?.totalStakedAmount.toFixed(3)) +
            Number(stakingSingTokenPair?.reserveOf(fwETH).toFixed(3)) * 2) *
            Number(ethPrice) +
          Number(newStakingTokenInfos[2]?.totalStakedAmount.toFixed(0))
      }
    }
    return poolsTvl + singPoolsTvl
  }, [
    WETH,
    chainId,
    ethPrice,
    fwETH,
    newStakingInfos,
    newStakingTokenInfos,
    stakingSingTokenPair,
    stakingTokenPairs,
    totalSupplyOfStakingTokens,
  ])
}
