import { CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'

import { useERC20Permit } from './useERC20Permit'
import useTransactionDeadline from './useTransactionDeadline'

export function useFewStakeTokenPermit(
  liquidityAmount: CurrencyAmount<Token> | null | undefined,
  spender: string | null | undefined
) {
  const transactionDeadline = useTransactionDeadline()
  return useERC20Permit(liquidityAmount, spender, transactionDeadline, undefined)
}
