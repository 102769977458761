import { createAction } from '@reduxjs/toolkit'

export enum Field {
  NAME = 'NAME',
  SYMBOL = 'SYMBOL',
  TOTAL_SUPPLY_AMOUNT = 'TOTAL_SUPPLY_AMOUNT',
  PAIR_AMOUNT = 'PAIR_AMOUNT',
  BUY_MEME_CURRENCY = 'BUY_MEME_CURRENCY',
}

export const typeInput = createAction<{ field: Field; typedValue: string; isETH: boolean }>('mintMeme/typeInputMint')
export const resetMintState = createAction<void>('mintMeme/resetMintState')
