import { Currency, CurrencyAmount } from 'few-sdk-core-multiple-network-2'
import { FewTokenState, useCreateToken } from 'lib/hooks/useApproval'
import { useCallback } from 'react'

import { useHasPendingCreate, useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
export { FewTokenState } from 'lib/hooks/useApproval'

function useGetAndTrackCreateFewToken(getApproval: ReturnType<typeof useCreateToken>[1]) {
  const addTransaction = useTransactionAdder()
  return useCallback(() => {
    return getApproval().then((pending) => {
      if (pending) {
        const { response, tokenAddress, spenderAddress: spender, amount } = pending
        addTransaction(response, {
          type: TransactionType.CREATE,
          tokenAddress,
          spender,
          amount: amount.quotient.toString(),
        })
      }
    })
  }, [addTransaction, getApproval])
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useCreateFewTokenCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string
): [FewTokenState, () => Promise<void>] {
  const [approval, getApproval] = useCreateToken(amountToApprove, spender, useHasPendingCreate)
  return [approval, useGetAndTrackCreateFewToken(getApproval)]
}
