import React, { forwardRef } from 'react'
import styled from 'styled-components'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string; disabled?: boolean }>`
  color: ${({ error, theme }) => (error ? theme.critical : theme.neutral1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: 0;
  position: relative;
  font-weight: 485;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: ${({ fontSize }) => fontSize ?? '28px'};
  text-align: ${({ align }) => align ?? 'left'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.neutral3};
    font-size: 16px;
  }
`

interface InputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'> {
  value: string
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  prependSymbol?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onUserInput, placeholder, prependSymbol, ...rest }: InputProps, ref) => {
    return (
      <StyledInput
        {...rest}
        ref={ref}
        value={prependSymbol && value ? prependSymbol + value : value}
        onChange={(event) => {
          const value = event.target.value
          const formattedValue = prependSymbol ? value.toString().slice(1) : value
          onUserInput(formattedValue)
        }}
        inputMode="text"
        autoComplete="off"
        autoCorrect="off"
        type="text"
        placeholder={placeholder || 'Enter text'}
        minLength={1}
        maxLength={79}
        spellCheck="false"
      />
    )
  }
)

Input.displayName = 'Input'

const MemoizedInput = React.memo(Input)
export { MemoizedInput as Input }
