import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { Scrim } from 'components/AccountDrawer'
import Column, { AutoColumn } from 'components/Column'
import RingXBrandMark from 'components/Logo/RingXBrandMark'
import Row from 'components/Row'
import { isMinerSupportedChain } from 'constants/miner'
import { Currency } from 'few-sdk-core-multiple-network-2'
import useDisableScrolling from 'hooks/useDisableScrolling'
import { useIsMiner } from 'hooks/useIsMiner'
import { useIsRingXPage, useIsSwapPage } from 'hooks/useIsSwapPage'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Portal } from 'nft/components/common/Portal'
import { useIsMobile } from 'nft/hooks'
import { useRef } from 'react'
import { useModalIsOpen, useToggleMinerSettings } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components'
import { CloseIcon, ThemedText } from 'theme'
import { Z_INDEX } from 'theme/zIndex'

import MinerButton from '../MinerButton'

const Menu = styled.div`
  position: relative;
`

const MenuFlyout = styled(AutoColumn)`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.surface1};
  border: 1px solid ${({ theme }) => theme.surface3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  right: 0;
  z-index: 100;
  color: ${({ theme }) => theme.neutral1};
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    min-width: 18.125rem;
  `};
  user-select: none;
  padding: 16px;
`

const MobileMenuContainer = styled(Row)`
  overflow: visible;
  position: fixed;
  height: 100%;
  top: 100vh;
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${Z_INDEX.fixed};
`

const MobileMenuWrapper = styled(Column)<{ open: boolean }>`
  height: min-content;
  width: 100%;
  padding: 8px 16px 24px;
  background-color: ${({ theme }) => theme.surface1};
  overflow: hidden;
  position: absolute;
  bottom: ${({ open }) => (open ? `100vh` : 0)};
  transition: bottom ${({ theme }) => theme.transition.duration.medium};
  border: ${({ theme }) => `1px solid ${theme.surface3}`};
  border-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 16px;
  box-shadow: unset;
  z-index: ${Z_INDEX.modal};
`

const MobileMenuHeader = styled(Row)`
  margin-bottom: 16px;
`

export default function MinerTab({
  chainId,
  currencies,
}: {
  chainId?: number
  currencies?: {
    INPUT?: Currency | null
    OUTPUT?: Currency | null
  }
}) {
  const { chainId: connectedChainId } = useWeb3React()
  const node = useRef<HTMLDivElement | null>(null)
  const isOpen = useModalIsOpen(ApplicationModal.MINER_SETTINGS)
  const isMiner = useIsMiner(chainId, currencies?.INPUT, currencies?.OUTPUT)
  const isSwapPage = useIsSwapPage()
  const isRingXPage = useIsRingXPage()

  const toggleMenu = useToggleMinerSettings()

  const isMobile = useIsMobile()
  const isOpenMobile = isOpen && isMobile
  const isOpenDesktop = isOpen && !isMobile

  useOnClickOutside(node, isOpenDesktop ? toggleMenu : undefined)
  useDisableScrolling(isOpen)

  const isChainSupported = isMinerSupportedChain(chainId)

  const MinerInfo = () => (
    <ThemedText.BodySmall>
      <Trans>You can earn blast gold points by transacting through the specified pair.</Trans>
    </ThemedText.BodySmall>
  )

  return (
    <>
      <Menu ref={node}>
        {isSwapPage && (
          <MinerButton
            isMiner={isMiner}
            disabled={!isChainSupported || chainId !== connectedChainId}
            isActive={isOpen}
            onClick={toggleMenu}
            currencies={currencies}
          />
        )}
        {isRingXPage && <RingXBrandMark />}
        {isOpenDesktop && !isMobile && (
          <MenuFlyout>
            <MinerInfo />
          </MenuFlyout>
        )}
      </Menu>
      {isMobile && (
        <Portal>
          <MobileMenuContainer data-testid="mobile-miner-settings-menu">
            <Scrim testId="mobile-miner-settings-scrim" onClick={toggleMenu} open={isOpenMobile} />
            <MobileMenuWrapper open={isOpenMobile}>
              <MobileMenuHeader padding="8px 0px 4px">
                <CloseIcon size={24} onClick={toggleMenu} />
                <Row padding="0px 24px 0px 0px" justify="center">
                  <ThemedText.SubHeader>
                    <Trans>Miner</Trans>
                  </ThemedText.SubHeader>
                </Row>
              </MobileMenuHeader>
              <MinerInfo />
            </MobileMenuWrapper>
          </MobileMenuContainer>
        </Portal>
      )}
    </>
  )
}
