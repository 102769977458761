/* eslint-disable import/no-unused-modules */
import { Currency, CurrencyAmount, Fraction } from 'few-sdk-core-multiple-network-2'
import { useOriginalTokenOfFewToken } from 'hooks/useWrappedToken'
import JSBI from 'jsbi'
import { unwrappedToDecimals } from 'utils/unwrappedToken'

const CURRENCY_AMOUNT_MIN = new Fraction(JSBI.BigInt(1), JSBI.BigInt(1000000))

export default function FormattedCurrencyAmount({
  currencyAmount,
  significantDigits = 4,
}: {
  currencyAmount: CurrencyAmount<Currency>
  significantDigits?: number
}) {
  return (
    <>
      {currencyAmount.equalTo(JSBI.BigInt(0))
        ? '0'
        : currencyAmount.greaterThan(CURRENCY_AMOUNT_MIN)
        ? currencyAmount.toSignificant(significantDigits)
        : `<${CURRENCY_AMOUNT_MIN.toSignificant(1)}`}
    </>
  )
}

export function useFormattedCurrencyAmount(
  currencyAmount: CurrencyAmount<Currency> | undefined
): CurrencyAmount<Currency> | undefined {
  const originToken = useOriginalTokenOfFewToken(currencyAmount?.currency.wrapped)
  if (originToken && currencyAmount) {
    const amount = unwrappedToDecimals(currencyAmount.quotient, originToken.decimals)
    const originCurrencyAmount = CurrencyAmount.fromRawAmount(currencyAmount.currency, amount)
    return originCurrencyAmount
  }
  return currencyAmount
}

export function FormattedV3CurrencyAmount({
  currencyAmount,
  significantDigits = 4,
}: {
  currencyAmount: CurrencyAmount<Currency>
  significantDigits?: number
}) {
  const originToken = useOriginalTokenOfFewToken(currencyAmount.currency.wrapped)
  if (originToken) {
    const amount = unwrappedToDecimals(currencyAmount.quotient, originToken.decimals)
    const originCurrencyAmount = CurrencyAmount.fromRawAmount(currencyAmount.currency, amount)

    return (
      <>
        {originCurrencyAmount.equalTo(JSBI.BigInt(0))
          ? '0'
          : originCurrencyAmount.greaterThan(CURRENCY_AMOUNT_MIN)
          ? originCurrencyAmount.toSignificant(significantDigits)
          : `<${CURRENCY_AMOUNT_MIN.toSignificant(1)}`}
      </>
    )
  }
  return <>0</>
}
