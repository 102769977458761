/* eslint-disable import/no-unused-modules */
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { ISwapResponse } from '@swapnet-xyz/sdk'
import { API_KEYS } from 'constants/ringx'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { Protocol } from 'few-router-sdk-multiple-network-4'
import { WETH9 } from 'few-sdk-core-multiple-network-2'
import { getClientSideQuote } from 'lib/hooks/routing/clientSideSmartOrderRouter'
import ms from 'ms'

import { GetQuoteArgs, QuoteMethod, QuoteState, TradeResult } from './types'
import {
  getRouter,
  isExactInput,
  transformRingXQuoteResponse,
  transformRingXRoutesToTrade,
  transformRoutesToTrade,
} from './utils'

const RINGX_API_URL = process.env.REACT_APP_RINGX_API_URL
if (RINGX_API_URL === undefined) {
  throw new Error(`RINGX_API_URL must be a defined environment variable`)
}

const CLIENT_PARAMS = {
  protocols: [Protocol.V2, Protocol.V3, Protocol.MIXED],
}

function getQuoteLatencyMeasure(mark: PerformanceMark): PerformanceMeasure {
  performance.mark('quote-fetch-end')
  return performance.measure('quote-fetch-latency', mark.name, 'quote-fetch-end')
}

export const ringXRoutingApi = createApi({
  reducerPath: 'ringXRoutingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: RINGX_API_URL,
  }),
  endpoints: (build) => ({
    getQuote: build.query<TradeResult, GetQuoteArgs>({
      async onQueryStarted(args: GetQuoteArgs, { queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error: unknown) {
          if (error && typeof error === 'object' && 'error' in error) {
            const queryError = (error as Record<'error', FetchBaseQueryError>).error
            if (typeof queryError.status === 'number') {
              console.log(queryError)
            }
            console.log(queryError)
          } else {
            throw error
          }
        }
      },
      async queryFn(args, _api, _extraOptions, fetch) {
        const quoteStartMark = performance.mark(`quote-fetch-start-${Date.now()}`)
        try {
          const { tokenInAddress, tokenInChainId, tokenOutAddress, tokenOutChainId, amount, tradeType } = args
          const type = isExactInput(tradeType) ? 'EXACT_INPUT' : 'EXACT_OUTPUT'

          const sellTokenInAddress =
            tokenInAddress === 'ETH'
              ? WRAPPED_NATIVE_CURRENCY[tokenInChainId]?.address ?? WETH9[tokenInChainId].address
              : tokenInAddress

          const buyTokenOutAddress =
            tokenOutAddress === 'ETH'
              ? WRAPPED_NATIVE_CURRENCY[tokenInChainId]?.address ?? WETH9[tokenInChainId].address
              : tokenOutAddress

          const chainId = tokenInChainId || tokenOutChainId
          const params = new URLSearchParams(
            type == 'EXACT_INPUT'
              ? {
                  chainId: chainId.toString(),
                  sellToken: sellTokenInAddress,
                  buyToken: buyTokenOutAddress,
                  sellAmount: amount,
                  apiKey: API_KEYS[chainId],
                }
              : {
                  chainId: chainId.toString(),
                  sellToken: sellTokenInAddress,
                  buyToken: buyTokenOutAddress,
                  buyAmount: amount,
                  apiKey: API_KEYS[chainId],
                }
          )

          const response = await fetch({
            method: 'GET',
            url: `/swap?${params}`,
          })

          if (response.error) {
            try {
              // cast as any here because we do a runtime check on it being an object before indexing into .errorCode
              const errorData = response.error.data as { errorCode?: string; detail?: string }
              // NO_ROUTE should be treated as a valid response to prevent retries.
              if (
                typeof errorData === 'object' &&
                (errorData?.errorCode === 'NO_ROUTE' || errorData?.detail === 'No quotes available')
              ) {
                return {
                  data: { state: QuoteState.NOT_FOUND, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
                }
              }
            } catch {
              throw response.error
            }
          }
          const uraQuoteResponse = transformRingXQuoteResponse(response.data as ISwapResponse, tokenInChainId)
          const tradeResult = await transformRingXRoutesToTrade(args, uraQuoteResponse, QuoteMethod.RING_X)
          return { data: { ...tradeResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
        } catch (error: any) {
          console.warn(
            `GetQuote failed on Unified Routing API, falling back to client: ${
              error?.message ?? error?.detail ?? error
            }`
          )
        }
        try {
          const router = getRouter(args.tokenInChainId)
          const quoteResult = await getClientSideQuote(args, router, CLIENT_PARAMS)
          if (quoteResult.state === QuoteState.SUCCESS) {
            const trade = await transformRoutesToTrade(args, quoteResult.data, QuoteMethod.CLIENT_SIDE_FALLBACK)
            return {
              data: { ...trade, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration },
            }
          } else {
            return { data: { ...quoteResult, latencyMs: getQuoteLatencyMeasure(quoteStartMark).duration } }
          }
        } catch (error: any) {
          console.warn(`GetQuote failed on client: ${error}`)
          return {
            error: { status: 'CUSTOM_ERROR', error: error?.detail ?? error?.message ?? error },
          }
        }
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuoteQuery } = ringXRoutingApi
export const useGetRingXQuoteQueryState = ringXRoutingApi.endpoints.getQuote.useQueryState
