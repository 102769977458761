import { Contract } from '@ethersproject/contracts'
import { InterfaceEventName } from '@uniswap/analytics-events'
import IUniswapV2PairJson from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import IUniswapV2Router02Json from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import TickLensJson from '@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import NonfungiblePositionManagerJson from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import V3MigratorJson from '@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json'
import V3StakerJson from '@uniswap/v3-staker/artifacts/contracts/UniswapV3Staker.sol/UniswapV3Staker.json'
import { useWeb3React } from '@web3-react/core'
import ARGENT_WALLET_DETECTOR_ABI from 'abis/argent-wallet-detector.json'
import EIP_2612 from 'abis/eip_2612.json'
import ENS_PUBLIC_RESOLVER_ABI from 'abis/ens-public-resolver.json'
import ENS_ABI from 'abis/ens-registrar.json'
import ERC20_ABI from 'abis/erc20.json'
import ERC20_BYTES32_ABI from 'abis/erc20_bytes32.json'
import ERC721_ABI from 'abis/erc721.json'
import ERC1155_ABI from 'abis/erc1155.json'
import FewETHWrapperJson from 'abis/FewETHWrapper.json'
import FewFactoryJson from 'abis/FewFactory.json'
import IFewSwapRouter01Json from 'abis/FewV1Router.json'
import FewWrappedTokenJson from 'abis/FewWrappedToken.json'
import MerkleDistributorWithDeadlineJson from 'abis/MerkleDistributorWithDeadline.json'
import RingLaunchpadJson from 'abis/RingLaunchpad.json'
import RingPointTokenConverterJson from 'abis/RingPointTokenConverter.json'
import RingTokenClaimerJson from 'abis/RingTokenClaimer.json'
import SWAP_INCENTIVE_ABI from 'abis/SwapIncentive.json'
import {
  ArgentWalletDetector,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc721,
  Erc1155,
  FewETHWrapper,
  FewFactory,
  FewV1Router,
  FewWrappedToken,
  RingLaunchpad,
  Weth,
} from 'abis/types'
import WETH_ABI from 'abis/weth.json'
import { sendAnalyticsEvent } from 'analytics'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'constants/multicall'
import { RPC_PROVIDERS } from 'constants/providers'
import {
  AirdropType,
  FEW_TOKEN_ROUTER_ADDRESSES,
  MEME_LAUNCHA_PAD_ADDRESSES,
  POINT_MERKLE_DISTRIBUTOR_ADDRESS,
  RING_V2_ROUTER_ADDRESSES,
  V2_ROUTER_ADDRESSES,
  V3_01_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  V3_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  V3_STAKER_ADDRESSES,
  WRAPPED_NATIVE_CURRENCY,
} from 'constants/tokens'
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  ChainId,
  ENS_REGISTRAR_ADDRESSES,
  MULTICALL_ADDRESSES,
  TICK_LENS_ADDRESSES,
  V3_MIGRATOR_ADDRESSES,
} from 'few-sdk-core-multiple-network-2'
import { useEffect, useMemo } from 'react'
import { NonfungiblePositionManager, TickLens, UniswapInterfaceMulticall, UniswapV3Staker } from 'types/v3'
import { V3Migrator } from 'types/v3/V3Migrator'
import { getContract } from 'utils'

import useParsedQueryString from './useParsedQueryString'

const { abi: IUniswapV2PairABI } = IUniswapV2PairJson
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json
const { abi: IFewSwapRouter01JsonABI } = IFewSwapRouter01Json
const { abi: MerkleDistributorWithDeadlineJsonABI } = MerkleDistributorWithDeadlineJson
const { abi: FewWrappedTokenABI } = FewWrappedTokenJson
const { abi: RingTokenClaimerABI } = RingTokenClaimerJson
const { abi: RingPointTokenConverterABI } = RingPointTokenConverterJson
const { abi: FewFactoryABI } = FewFactoryJson
const { abi: RingLaunchpadABI } = RingLaunchpadJson
const { abi: FewETHWrapperABI } = FewETHWrapperJson

const { abi: TickLensABI } = TickLensJson
const { abi: MulticallABI } = UniswapInterfaceMulticallJson
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson
const { abi: V2MigratorABI } = V3MigratorJson
const { abi: V3StakerABI } = V3StakerJson

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

function useMainnetContract<T extends Contract = Contract>(address: string | undefined, ABI: any): T | null {
  const { chainId } = useWeb3React()
  const isMainnet = chainId === ChainId.MAINNET
  const contract = useContract(isMainnet ? address : undefined, ABI, false)
  return useMemo(() => {
    if (isMainnet) return contract
    if (!address) return null
    const provider = RPC_PROVIDERS[ChainId.MAINNET]
    try {
      return getContract(address, ABI, provider)
    } catch (error) {
      console.error('Failed to get mainnet contract', error)
      return null
    }
  }, [address, ABI, contract, isMainnet]) as T
}

// eslint-disable-next-line import/no-unused-modules
export function useV2MigratorContract() {
  return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useFewFactoryContract(FewFactoryAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<FewFactory>(FewFactoryAddress, FewFactoryABI, withSignerIfPossible)
}

export function useRingLaunchpadContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract<RingLaunchpad>(MEME_LAUNCHA_PAD_ADDRESSES[chainId ?? ChainId.MAINNET], RingLaunchpadABI, true)
}

export function useFewTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract<FewWrappedToken>(tokenAddress, FewWrappedTokenABI, withSignerIfPossible)
}

export function useTokenConverterContract(tokenAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(tokenAddress, RingPointTokenConverterABI, withSignerIfPossible)
}

export function useClaimerContract(ringTokenClaimerAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(ringTokenClaimerAddress, RingTokenClaimerABI, withSignerIfPossible)
}

export function useFewETHWrapperContract(FewETHWrapper?: string) {
  return useContract<FewETHWrapper>(FewETHWrapper, FewETHWrapperABI, true)
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React()
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false)
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false)
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(ARGENT_WALLET_DETECTOR_ADDRESS, ARGENT_WALLET_DETECTOR_ABI, false)
}

export function useENSRegistrarContract() {
  return useMainnetContract<EnsRegistrar>(ENS_REGISTRAR_ADDRESSES[ChainId.MAINNET], ENS_ABI)
}

export function useENSResolverContract(address: string | undefined) {
  return useMainnetContract<EnsPublicResolver>(address, ENS_PUBLIC_RESOLVER_ABI)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useWeb3React()
  const address = chainId ? MULTICALL_NETWORKS[chainId] : undefined
  return useContract(address, MULTICALL_ABI, false)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useV2RouterContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(V2_ROUTER_ADDRESSES[chainId ?? ChainId.BLAST], IUniswapV2Router02ABI, true)
}

export function useFewV1RouterContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract<FewV1Router>(V2_ROUTER_ADDRESSES[chainId ?? ChainId.BLAST], IFewSwapRouter01JsonABI, true)
}

// eslint-disable-next-line import/no-unused-modules
export function useMerkleDistributorWithDeadlineContract(airdrop: AirdropType): Contract | null {
  return useContract(POINT_MERKLE_DISTRIBUTOR_ADDRESS[airdrop], MerkleDistributorWithDeadlineJsonABI, true)
}

export function useFewTokenRouterContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract(FEW_TOKEN_ROUTER_ADDRESSES[chainId ?? ChainId.BLAST], IFewSwapRouter01JsonABI, true)
}

export function useRingV2RouterContract(): Contract | null {
  const { chainId } = useWeb3React()
  return useContract<FewV1Router>(RING_V2_ROUTER_ADDRESSES[chainId ?? ChainId.BLAST], IFewSwapRouter01JsonABI, true)
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(MULTICALL_ADDRESSES, MulticallABI, false) as UniswapInterfaceMulticall
}

export function useMainnetInterfaceMulticall() {
  return useMainnetContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESSES[ChainId.MAINNET],
    MulticallABI
  ) as UniswapInterfaceMulticall
}

export function useV3NFTPositionManagerContract(withSignerIfPossible?: boolean): NonfungiblePositionManager | null {
  const { account, chainId } = useWeb3React()
  const { version } = useParsedQueryString()

  const contract = useContract<NonfungiblePositionManager>(
    version === 'v1.0.1'
      ? V3_01_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId ?? ChainId.BLAST]
      : V3_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId ?? ChainId.BLAST],
    NFTPositionManagerABI,
    withSignerIfPossible
  )
  useEffect(() => {
    if (contract && account) {
      sendAnalyticsEvent(InterfaceEventName.WALLET_PROVIDER_USED, {
        source: 'useV3NFTPositionManagerContract',
        contract,
      })
    }
  }, [account, contract])
  return contract
}

export function useTickLens(): TickLens | null {
  const { chainId } = useWeb3React()
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined
  return useContract(address, TickLensABI) as TickLens | null
}

export function useV3Staker() {
  return useContract<UniswapV3Staker>(V3_STAKER_ADDRESSES, V3StakerABI)
}

export function useSwapIncentiveContract(stakingAddress?: string, withSignerIfPossible?: boolean) {
  return useContract(stakingAddress, SWAP_INCENTIVE_ABI, withSignerIfPossible)
}
