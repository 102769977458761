import { Pool, Position } from 'few-v3-sdk-multiple-network-3'
import { usePool } from 'hooks/usePools'
import { PositionDetails } from 'types/position'

import { useCurrency } from './Tokens'
import { useOriginalTokenOfFewToken, useWrappedTokenFromOriginal } from './useWrappedToken'

export function useDerivedPositionInfo(positionDetails: PositionDetails | undefined): {
  position?: Position
  pool?: Pool
} {
  const currency0 = useCurrency(positionDetails?.token0)
  const currency1 = useCurrency(positionDetails?.token1)

  const originalToken0 = useOriginalTokenOfFewToken(currency0?.wrapped)
  const originalToken1 = useOriginalTokenOfFewToken(currency1?.wrapped)

  const fewCurrency0 = useWrappedTokenFromOriginal(originalToken0)
  const fewCurrency1 = useWrappedTokenFromOriginal(originalToken1)

  // construct pool data
  const [, pool] = usePool(fewCurrency0 ?? undefined, fewCurrency1 ?? undefined, positionDetails?.fee)

  let position = undefined
  if (pool && positionDetails) {
    position = new Position({
      pool,
      liquidity: positionDetails.liquidity.toString(),
      tickLower: positionDetails.tickLower,
      tickUpper: positionDetails.tickUpper,
    })
  }

  return {
    position,
    pool: pool ?? undefined,
  }
}
