import ERC20JSON from 'abis/erc20.json'
import { Currency, CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'
import { Interface } from 'ethers/lib/utils'
import { useMultipleContractSingleData, useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { StakingInfo } from 'state/stake/hooks'

import { useTokenContract } from './useContract'

const ERC20_INTERFACE = new Interface(ERC20JSON)

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useTotalSupply(token?: Currency): CurrencyAmount<Token> | undefined {
  const contract = useTokenContract(token?.isToken ? token.address : undefined, false)

  const totalSupplyStr: string | undefined = useSingleCallResult(contract, 'totalSupply')?.result?.[0]?.toString()

  return useMemo(
    () => (token?.isToken && totalSupplyStr ? CurrencyAmount.fromRawAmount(token, totalSupplyStr) : undefined),
    [token, totalSupplyStr]
  )
}

export function useAllTotalSupply(infos: Array<StakingInfo>): Array<CurrencyAmount<Token> | undefined> {
  const tokensAddresses = useMemo(() => infos.map(({ stakedAmount }) => stakedAmount.currency.wrapped.address), [infos])

  const tokensTotalSupplies = useMultipleContractSingleData(tokensAddresses, ERC20_INTERFACE, 'totalSupply')

  return useMemo(() => {
    return infos && tokensTotalSupplies.length && infos.length
      ? tokensTotalSupplies.map((supply, index) => {
          if (!tokensTotalSupplies[index]) return undefined
          const { result: tokenTotalSupply } = tokensTotalSupplies[index]

          if (!tokenTotalSupply) return undefined

          try {
            return CurrencyAmount.fromRawAmount(infos[index].stakedAmount.currency, tokenTotalSupply[0].toString())
          } catch (error) {
            console.error('Error when constructing the tokenTotalSupply', error)
            return undefined
          }
        })
      : []
  }, [infos, tokensTotalSupplies])
}
