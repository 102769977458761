import { Interface } from '@ethersproject/abi'
import IUniswapV2PairJSON from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { RING_V2_FACTORY_ADDRESSES } from 'constants/tokens'
import { Currency, CurrencyAmount } from 'few-sdk-core-multiple-network-2'
import { INIT_CODE_HASH_MAP } from 'few-v2-sdk-multiple-network-4'
import { computePairAddress as computeRingPairAddress } from 'few-v2-sdk-multiple-network-4'
import { Pair } from 'few-v2-sdk-multiple-network-4'
import { useMultipleContractSingleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'
// import { Pair } from 'few-v2-sdk-multiple-network-4'

const PAIR_INTERFACE = new Interface(IUniswapV2PairJSON.abi)

enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function useRingPairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const tokens = useMemo(
    () => currencies.map(([currencyA, currencyB]) => [currencyA?.wrapped, currencyB?.wrapped]),
    [currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA &&
          tokenB &&
          tokenA.chainId === tokenB.chainId &&
          !tokenA.equals(tokenB) &&
          RING_V2_FACTORY_ADDRESSES[tokenA.chainId]
          ? computeRingPairAddress({
              initCodeHash: INIT_CODE_HASH_MAP[tokenA.chainId],
              factoryAddress: RING_V2_FACTORY_ADDRESSES[tokenA.chainId],
              tokenA,
              tokenB,
            })
          : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString())
        ),
      ]
    })
  }, [results, tokens])
}

export function useRingPair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const inputs: [[Currency | undefined, Currency | undefined]] = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return useRingPairs(inputs)[0]
}
