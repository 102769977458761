/* eslint-disable import/no-unused-modules */
import { isMinerSupportedChain, MINER_SUPPORTED_PAIRS } from 'constants/miner'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { ChainId, Currency, CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'
import { Pair } from 'few-v2-sdk-multiple-network-4'
import { useMemo } from 'react'

/**
 * Returns true if the input currency or output currency cannot be traded in the interface
 * @param currencyIn the input currency to check
 * @param currencyOut the output currency to check
 */
export function useIsMiner(
  chainId: ChainId | undefined,
  currencyIn?: Currency | null,
  currencyOut?: Currency | null
): boolean {
  const isSupportedChain = isMinerSupportedChain(chainId)

  return useMemo(() => {
    if (!chainId || !isSupportedChain || !currencyIn || !currencyOut) {
      return false
    }
    const supportedPairs = MINER_SUPPORTED_PAIRS[chainId]

    if (Boolean(currencyIn.isToken) && Boolean(currencyOut.isToken)) {
      const swapPair = new Pair(
        CurrencyAmount.fromRawAmount(currencyIn as Token, '0'),
        CurrencyAmount.fromRawAmount(currencyOut as Token, '0')
      )
      const isMiner = supportedPairs.some(
        (pair: Pair) => pair.liquidityToken.address === swapPair.liquidityToken.address
      )
      return isMiner
    } else if (currencyIn.isNative || currencyOut.isNative) {
      const [newCurrencyIn, newCurrencyOut] = currencyIn.isNative
        ? [WRAPPED_NATIVE_CURRENCY[chainId], currencyOut as Token]
        : [currencyIn as Token, WRAPPED_NATIVE_CURRENCY[chainId]]

      if (newCurrencyIn?.address === newCurrencyOut?.address) {
        return false
      }

      const swapPair = new Pair(
        CurrencyAmount.fromRawAmount(newCurrencyIn as Token, '0'),
        CurrencyAmount.fromRawAmount(newCurrencyOut as Token, '0')
      )
      const isMiner = supportedPairs.some(
        (pair: Pair) => pair.liquidityToken.address === swapPair.liquidityToken.address
      )
      return isMiner
    }

    return false
  }, [chainId, currencyIn, currencyOut, isSupportedChain])
}
