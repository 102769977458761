import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import Badge, { BadgeVariant } from 'components/Badge'
import { BIG_INT_SECONDS_IN_YEAR } from 'constants/misc'
import { FEW_USDB, FEW_WRAPPED_NATIVE_CURRENCY, USDB } from 'constants/tokens'
import { ChainId, CurrencyAmount, Token, WETH9 } from 'few-sdk-core-multiple-network-2'
import { Pair } from 'few-v2-sdk-multiple-network-4'
import JSBI from 'jsbi'
import { DataRow } from 'pages/Earn'
import { Countdown } from 'pages/Earn/Countdown'
import { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { useColor } from '../../../hooks/useColor'
import useStablecoinPrice from '../../../hooks/useStablecoinPrice'
import { useTotalSupply } from '../../../hooks/useTotalSupply'
import { useV2Pair } from '../../../hooks/useV2Pairs'
// import { FewStakingInfo } from '../../../state/stake/hooks'
import { StakingInfo } from '../../../state/stake/hooks'
import { ExternalLink, StyledInternalLink, ThemedText } from '../../../theme'
import { currencyId } from '../../../utils/currencyId'
import { unwrappedToken } from '../../../utils/unwrappedToken'
import { ButtonPrimary } from '../../Button'
import { AutoColumn } from '../../Column'
import Row, { RowBetween } from '../../Row'
import { Break, CardBGImage, CardNoise } from './styled'

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  display: none;
`};
`

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  padding: 2px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${
      showBackground ? theme.black : theme.deprecated_bg5
    } 100%) `};
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.textPrimary)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function FewPoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const { chainId } = useWeb3React()
  const theme = useTheme()

  // const token0 = stakingInfo.tokens[0]
  // const token1 = stakingInfo.tokens[1]
  const originalToken0 = stakingInfo.originalTokens[0]
  const originalToken1 = stakingInfo.originalTokens[1]

  // const currency0 = unwrappedToken(token0)
  // const currency1 = unwrappedToken(token1)
  const originalCurrency0 = unwrappedToken(originalToken0)
  const originalCurrency1 = unwrappedToken(originalToken1)

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  const token =
    originalToken0.wrapped.address === WETH9[chainId ?? ChainId.BLAST].address ? originalToken1 : originalToken0
  const WETH =
    originalToken0.wrapped.address === WETH9[chainId ?? ChainId.BLAST].address ? originalToken0 : originalToken1
  const fwETH = FEW_WRAPPED_NATIVE_CURRENCY[chainId ?? ChainId.BLAST]
  const fwUSDB = FEW_USDB[chainId ?? ChainId.BLAST]
  const usdb = USDB[chainId ?? ChainId.BLAST]

  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.currency)
  const [, stakingTokenPair] = useV2Pair(...stakingInfo.tokens)

  const powerOfTen = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(18))

  // get WETH value of staked LP tokens
  let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined
  // get USDB value of staked LP tokens
  let valueOfTotalStakedAmountInUSDB: CurrencyAmount<Token> | undefined

  if (stakingInfo.tokens.includes(fwETH)) {
    if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && fwETH) {
      // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
      valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
        WETH,
        JSBI.divide(
          JSBI.multiply(
            JSBI.multiply(stakingInfo.totalStakedAmount.quotient, stakingTokenPair.reserveOf(fwETH).quotient),
            JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
          ),
          totalSupplyOfStakingToken.quotient
        )
      )
    }
  } else if (stakingInfo.tokens.includes(fwUSDB)) {
    if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && usdb && fwUSDB) {
      // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
      valueOfTotalStakedAmountInUSDB = CurrencyAmount.fromRawAmount(
        usdb,
        JSBI.divide(
          JSBI.multiply(
            JSBI.multiply(stakingInfo.totalStakedAmount.quotient, stakingTokenPair.reserveOf(fwUSDB).quotient),
            JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
          ),
          totalSupplyOfStakingToken.quotient
        )
      )
    }
  } else {
    valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(WETH, 0)
    valueOfTotalStakedAmountInUSDB = CurrencyAmount.fromRawAmount(usdb, 0)
  }

  const useStakedValues = (
    stakingInfo: StakingInfo,
    WETH: Token,
    usdb: Token,
    powerOfTen: JSBI,
    stakingTokenPair: Pair | null | undefined,
    totalSupplyOfStakingToken?: CurrencyAmount<Token>
  ) => {
    return useMemo(() => {
      // get WETH value of staked LP tokens
      let totalStakedAmountInWETH: CurrencyAmount<Token> | undefined
      // get USDB value of staked LP tokens
      let totalStakedAmountInUSDB: CurrencyAmount<Token> | undefined
      if (stakingInfo.tokens.includes(fwETH)) {
        if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && fwETH) {
          // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
          totalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
            WETH,
            JSBI.multiply(
              JSBI.divide(
                JSBI.multiply(stakingTokenPair.reserveOf(fwETH).quotient, powerOfTen),
                totalSupplyOfStakingToken.quotient
              ),
              JSBI.BigInt(2)
            )
          )
        }
        return {
          totalStakedAmountInWETH,
          totalStakedAmountInUSDB,
        }
      } else if (stakingInfo.tokens.includes(fwUSDB)) {
        if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && usdb && fwUSDB) {
          // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
          totalStakedAmountInUSDB = CurrencyAmount.fromRawAmount(
            usdb,
            JSBI.multiply(
              JSBI.divide(
                JSBI.multiply(stakingTokenPair.reserveOf(fwUSDB).quotient, powerOfTen),
                totalSupplyOfStakingToken.quotient
              ),
              JSBI.BigInt(2)
            )
          )
        }
        return {
          totalStakedAmountInWETH,
          totalStakedAmountInUSDB,
        }
      } else {
        return {
          totalStakedAmountInWETH: CurrencyAmount.fromRawAmount(WETH, 0),
          totalStakedAmountInUSDB: CurrencyAmount.fromRawAmount(usdb, 0),
        }
      }
    }, [stakingInfo, totalSupplyOfStakingToken, stakingTokenPair, WETH, powerOfTen, usdb])
  }

  const { totalStakedAmountInWETH, totalStakedAmountInUSDB } = useStakedValues(
    stakingInfo,
    WETH,
    usdb,
    powerOfTen,
    stakingTokenPair,
    totalSupplyOfStakingToken
  )

  // get the USD value of staked WETH
  const USDPrice = useStablecoinPrice(WETH)
  // get the USD value of staked USDB
  const USDBPrice = useStablecoinPrice(usdb)

  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const usdbValueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInUSDB && USDBPrice?.quote(valueOfTotalStakedAmountInUSDB)

  const valueOfTotalStakedAmountInWETHPerLP = totalStakedAmountInWETH && USDPrice?.quote(totalStakedAmountInWETH)

  const usdbValueOfTotalStakedAmountInPerLP = totalStakedAmountInUSDB && USDBPrice?.quote(totalStakedAmountInUSDB)

  let newPool
  let governanceToken

  if (stakingInfo.earnedAmount.currency.chainId === ChainId.BLAST) {
    newPool = 'mainnet_pool'
    governanceToken = 'POINT'
  } else if (
    stakingInfo.stakingRewardAddress === '0x366Ac78214aFE145Ca35d4A6513F4eD9e8909Fe8' ||
    stakingInfo.stakingRewardAddress === '0x3f6d0620d655Dc618Cb956cc2073644f92736616'
  ) {
    newPool = 'newPool'
    governanceToken = 'POINT'
  } else {
    newPool = 'oldPool'
    governanceToken = 'RGB'
  }

  const unclaimedAmount = stakingInfo?.earnedAmount.subtract(stakingInfo?.claimedAmount)?.toFixed(6) ?? '0'
  // const { data: ringUsdPrice } = useUSDPrice(tryParseCurrencyAmount('1', RING[ChainId.BLAST]))
  const ringpointPrice = 0.01
  const totalRewardAmount = stakingInfo.totalRewardRate?.multiply(BIG_INT_SECONDS_IN_YEAR).toSignificant()
  const totalRewardUsd = ringpointPrice && Number(totalRewardAmount) * ringpointPrice
  const isHighlight = stakingInfo.key == 23 ? true : false

  const [isActivityTime, setIsActivityTime] = useState(false)
  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000)
    const targetTimestamp = 1730995200 // End in Nov 8
    if (currentTimestamp < targetTimestamp) {
      setIsActivityTime(true)
    } else {
      setIsActivityTime(false)
    }
  }, [])

  return (
    <div>
      <DataRow style={{ alignItems: 'baseline' }}>
        <Countdown exactEnd={stakingInfo?.periodFinish} />
      </DataRow>
      {isHighlight && (
        <ThemedText.DeprecatedBlack fontSize={14}>
          <Trans>
            Discover ETHx leveraged strategies in detail{' '}
            <ExternalLink
              className="inline-block !underline"
              style={{ color: theme.darkMode ? theme.white : theme.black }}
              target="_blank"
              href="https://wand.fi/vaults"
            >
              <ThemedText.DeprecatedBlack fontSize={14}>
                <Trans>HERE ↗</Trans>
              </ThemedText.DeprecatedBlack>
            </ExternalLink>
          </Trans>
        </ThemedText.DeprecatedBlack>
      )}
      <div className="mt-2">
        <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
          <CardBGImage desaturate />
          <CardNoise />

          <Row className="!p-4">
            <PortfolioLogo
              chainId={chainId as ChainId}
              currencies={[originalCurrency0, originalCurrency1]}
              hiddenNetWork={true}
            />
            <ThemedText.DeprecatedWhite className="flex-1 pl-2 text-xl md:text-2xl items-center flex" fontWeight={600}>
              {originalCurrency0.symbol}-{originalCurrency1.symbol}
              {isHighlight && isActivityTime && (
                <Badge variant={BadgeVariant.BRANDED} className="!py-0.5 !px-1 text-xs ml-4">
                  3x APY
                </Badge>
              )}
            </ThemedText.DeprecatedWhite>
            <StyledInternalLink
              to={`/earn/${currencyId(originalCurrency0)}/${currencyId(originalCurrency1)}/${newPool}`}
            >
              <ButtonPrimary className="!py-1.5 md:!py-2 !px-2 md:!px-4" $borderRadius="8px">
                {isStaking ? <Trans>Manage</Trans> : <Trans>Deposit</Trans>}
              </ButtonPrimary>
            </StyledInternalLink>
          </Row>
          <StatContainer>
            <RowBetween>
              <ThemedText.DeprecatedWhite>
                <Trans>Total deposited</Trans>
              </ThemedText.DeprecatedWhite>
              <ThemedText.DeprecatedWhite>
                {valueOfTotalStakedAmountInUSDC ? (
                  <Trans>${valueOfTotalStakedAmountInUSDC.toFixed(2, { groupSeparator: ',' })}</Trans>
                ) : usdbValueOfTotalStakedAmountInUSDC ? (
                  <Trans>${usdbValueOfTotalStakedAmountInUSDC.toFixed(2, { groupSeparator: ',' })}</Trans>
                ) : (
                  <Trans>
                    {valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'}{' '}
                    {originalCurrency0.symbol}-{originalCurrency1.symbol}
                  </Trans>
                )}
              </ThemedText.DeprecatedWhite>
            </RowBetween>
            <RowBetween>
              <ThemedText.DeprecatedWhite>
                <Trans>Pool rate per LP</Trans>
              </ThemedText.DeprecatedWhite>
              <ThemedText.DeprecatedWhite>
                {stakingInfo ? (
                  stakingInfo.active ? (
                    <Trans>
                      {stakingInfo.totalRewardRate
                        ?.multiply(BIG_INT_SECONDS_IN_YEAR)
                        ?.toFixed(2, { groupSeparator: ',' })}{' '}
                      {governanceToken} / year
                    </Trans>
                  ) : (
                    <Trans>0 {governanceToken} / year</Trans>
                  )
                ) : (
                  '-'
                )}
              </ThemedText.DeprecatedWhite>
            </RowBetween>
            <RowBetween>
              <ThemedText.DeprecatedWhite>
                <Trans>APR (excluding Blast Gold Point rewards)</Trans>
              </ThemedText.DeprecatedWhite>
              <ThemedText.DeprecatedWhite>
                {valueOfTotalStakedAmountInWETHPerLP && totalRewardUsd ? (
                  <Trans>
                    {(100 * (totalRewardUsd / Number(valueOfTotalStakedAmountInWETHPerLP.toSignificant()))).toFixed(2)}{' '}
                    %
                  </Trans>
                ) : (
                  usdbValueOfTotalStakedAmountInPerLP &&
                  totalRewardUsd && (
                    <Trans>
                      {(100 * (totalRewardUsd / Number(usdbValueOfTotalStakedAmountInPerLP.toSignificant()))).toFixed(
                        2
                      )}{' '}
                      %
                    </Trans>
                  )
                )}
              </ThemedText.DeprecatedWhite>
            </RowBetween>
            {Number(unclaimedAmount) > 0.000001 && (
              <RowBetween>
                <ThemedText.DeprecatedWhite>
                  <Trans>Your unclaimed {governanceToken}</Trans>
                </ThemedText.DeprecatedWhite>
                <ThemedText.DeprecatedWhite>
                  <Trans>{unclaimedAmount}</Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>
            )}
          </StatContainer>

          {isStaking && (
            <>
              <Break />
              <BottomSection showBackground={true}>
                <ThemedText.DeprecatedBlack color="white" fontWeight={500}>
                  <span>
                    <Trans>Your rate per LP</Trans>
                  </span>
                </ThemedText.DeprecatedBlack>

                <ThemedText.DeprecatedBlack style={{ textAlign: 'right' }} color="white" fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                    ⚡
                  </span>
                  {stakingInfo ? (
                    stakingInfo.active ? (
                      <Trans>
                        {stakingInfo.rewardRate
                          ?.multiply(BIG_INT_SECONDS_IN_YEAR)
                          ?.toSignificant(4, { groupSeparator: ',' })}{' '}
                        {governanceToken} / year
                      </Trans>
                    ) : (
                      <Trans>0 {governanceToken} / year</Trans>
                    )
                  ) : (
                    '-'
                  )}
                </ThemedText.DeprecatedBlack>
              </BottomSection>
            </>
          )}
        </Wrapper>
      </div>
    </div>
  )
}
