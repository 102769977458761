import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { Currency, CurrencyAmount } from 'few-sdk-core-multiple-network-2'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { ReactNode, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { useCurrencyBalances } from '../../connection/hooks'
import { AppState } from '../../reducer'
import { Field, typeInput } from './actions'

export function useMintMemeState(): AppState['mintMeme'] {
  return useAppSelector((state) => state.mintMeme)
}

// eslint-disable-next-line import/no-unused-modules
export function useMintMemeActionHandlers(isETH: boolean | undefined): {
  onFieldNameInput: (typedValue: string) => void
  onFieldSymbolInput: (typedValue: string) => void
  onFieldTotalSupplyInput: (typedValue: string) => void
  onFieldPairAmountInput: (typedValue: string) => void
  onFieldBuyTokenInput: (typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onFieldNameInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.NAME, typedValue, isETH: isETH === true }))
    },
    [dispatch, isETH]
  )
  const onFieldSymbolInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.SYMBOL, typedValue, isETH: isETH === true }))
    },
    [dispatch, isETH]
  )
  const onFieldTotalSupplyInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.TOTAL_SUPPLY_AMOUNT, typedValue, isETH: isETH === true }))
    },
    [dispatch, isETH]
  )
  const onFieldPairAmountInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.PAIR_AMOUNT, typedValue, isETH: isETH === true }))
    },
    [dispatch, isETH]
  )
  const onFieldBuyTokenInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.BUY_MEME_CURRENCY, typedValue, isETH: isETH === true }))
    },
    [dispatch, isETH]
  )
  return {
    onFieldNameInput,
    onFieldSymbolInput,
    onFieldTotalSupplyInput,
    onFieldPairAmountInput,
    onFieldBuyTokenInput,
  }
}

// eslint-disable-next-line import/no-unused-modules
export function useMemeDerivedMintInfo(currencyA: Currency | undefined): {
  totalSupplyField: Field
  pairAmountField: Field
  buyMemeField: Field
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> }
  parsedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  parsedTokenWords: { [field in Field]?: string }
  isETH?: boolean
  error?: ReactNode
} {
  const { account } = useWeb3React()

  const {
    nameField,
    symbolField,
    totalSupplyField,
    pairAmountField,
    buyMemeField,
    nameTypedValue,
    symbolTypedValue,
    totalSupplyTypedValue,
    pairAmountTypedValue,
    buyTokenTypedValue,
  } = useMintMemeState()

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.BUY_MEME_CURRENCY]: currencyA ?? undefined,
    }),
    [currencyA]
  )

  // const noLiquidity: boolean = currencyA?.isNative ?? false

  // balances
  const balances = useCurrencyBalances(
    account ?? undefined,
    useMemo(() => [currencies[Field.BUY_MEME_CURRENCY]], [currencies])
  )
  const currencyBalances: { [field in Field]?: CurrencyAmount<Currency> } = {
    [Field.BUY_MEME_CURRENCY]: balances[0],
  }

  // amounts
  const totalSuppplyAmount: CurrencyAmount<Currency> | undefined = tryParseCurrencyAmount(
    totalSupplyTypedValue,
    currencies[buyMemeField]
  )
  const pairAmount: CurrencyAmount<Currency> | undefined = tryParseCurrencyAmount(
    pairAmountTypedValue,
    currencies[buyMemeField]
  )
  const independentAmount: CurrencyAmount<Currency> | undefined = tryParseCurrencyAmount(
    buyTokenTypedValue,
    currencies[buyMemeField]
  )
  const parsedTokenWords: Record<Field.NAME | Field.SYMBOL, string | undefined> = useMemo(() => {
    return {
      [Field.NAME]: nameTypedValue,
      [Field.SYMBOL]: symbolTypedValue,
    }
  }, [nameTypedValue, symbolTypedValue])

  const parsedAmounts: Record<
    Field.TOTAL_SUPPLY_AMOUNT | Field.PAIR_AMOUNT | Field.BUY_MEME_CURRENCY,
    CurrencyAmount<Currency> | undefined
  > = useMemo(() => {
    return {
      [Field.TOTAL_SUPPLY_AMOUNT]: totalSuppplyAmount,
      [Field.PAIR_AMOUNT]: pairAmount,
      [Field.BUY_MEME_CURRENCY]: independentAmount,
    }
  }, [independentAmount, pairAmount, totalSuppplyAmount])

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }

  if (!parsedTokenWords[Field.NAME]) {
    error = error ?? <Trans>Enter a token name</Trans>
  }

  if (!parsedTokenWords[Field.SYMBOL]) {
    error = error ?? <Trans>Enter a token symbol</Trans>
  }

  // if (!parsedAmounts[Field.TOTAL_SUPPLY_AMOUNT]) {
  //   error = error ?? <Trans>Enter a total supply amount</Trans>
  // }

  if (!parsedAmounts[Field.PAIR_AMOUNT]) {
    error = error ?? <Trans>Enter a pair amount</Trans>
  }

  const { [Field.BUY_MEME_CURRENCY]: currencyAAmount } = parsedAmounts

  if (currencyAAmount && currencyBalances?.[Field.BUY_MEME_CURRENCY]?.lessThan(currencyAAmount)) {
    error = <Trans>Insufficient {currencies[Field.BUY_MEME_CURRENCY]?.symbol} balance</Trans>
  }

  return {
    totalSupplyField,
    pairAmountField,
    buyMemeField,
    currencies,
    currencyBalances,
    parsedAmounts,
    parsedTokenWords,
    // noLiquidity,
    error,
  }
}
