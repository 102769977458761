import { Trans } from '@lingui/macro'
import { ReactNode } from 'react'
import { Box } from 'rebass'
import styled from 'styled-components'
import { ThemedText } from 'theme'
import { flexRowNoWrap } from 'theme/styles'

import { RowBetween } from '../Row'

const Tabs = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const AddRemoveTitleText = styled(ThemedText.SubHeaderLarge)`
  flex: 1;
  margin: auto;
`

export function MemeLaunchPadTabs({ children }: { showBackLink?: boolean; children?: ReactNode }) {
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem 1rem 0 1rem' }}>
        <AddRemoveTitleText textAlign={children ? 'start' : 'center'}>
          <Trans>Launch a Token</Trans>
        </AddRemoveTitleText>
        {children && <Box style={{ marginRight: '.5rem' }}>{children}</Box>}
      </RowBetween>
    </Tabs>
  )
}
