import { ChainId, CurrencyAmount, Token } from 'few-sdk-core-multiple-network-2'
import { Pair } from 'few-v2-sdk-multiple-network-4'

import { USDB_BLAST, USDB_BLAST_SEPOLIA, WETH_BLAST, WRAPPED_NATIVE_CURRENCY } from './tokens'

const MinerSupportedChainId = [ChainId.BLAST, ChainId.BLAST_SEPOLIA]

const ORBIT_BLAST = new Token(
  ChainId.BLAST,
  '0x42E12D42b3d6C4A74a88A61063856756Ea2DB357',
  18,
  'ORBIT',
  'Orbit Protocol'
)
const WBTC_BLAST = new Token(ChainId.BLAST, '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692', 8, 'WBTC', 'Wrapped BTC')
const JUICE_BLAST = new Token(ChainId.BLAST, '0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923', 18, 'JUICE', 'Juice')
const BAC_BLAST = new Token(ChainId.BLAST, '0xe20d45377370135c733876548de202040cfc0664', 18, 'BAC', 'BAC Token')
const DETH_BLAST = new Token(ChainId.BLAST, '0x1Da40C742F32bBEe81694051c0eE07485fC630f6', 18, 'DETH', 'Duo ETH')
const DUSD_BLAST = new Token(ChainId.BLAST, '0x1A3D9B2fa5c6522c8c071dC07125cE55dF90b253', 18, 'DUSD', 'Duo USD')

export const MINER_SUPPORTED_PAIRS: { [chainId: number]: Pair[] } = {
  [ChainId.BLAST]: [
    new Pair(CurrencyAmount.fromRawAmount(WETH_BLAST, '0'), CurrencyAmount.fromRawAmount(USDB_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(WETH_BLAST, '0'), CurrencyAmount.fromRawAmount(WBTC_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(WETH_BLAST, '0'), CurrencyAmount.fromRawAmount(JUICE_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(WETH_BLAST, '0'), CurrencyAmount.fromRawAmount(ORBIT_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(USDB_BLAST, '0'), CurrencyAmount.fromRawAmount(BAC_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(WETH_BLAST, '0'), CurrencyAmount.fromRawAmount(DETH_BLAST, '0')),
    new Pair(CurrencyAmount.fromRawAmount(USDB_BLAST, '0'), CurrencyAmount.fromRawAmount(DUSD_BLAST, '0')),
  ],
  [ChainId.BLAST_SEPOLIA]: [
    new Pair(
      CurrencyAmount.fromRawAmount(WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA] as Token, '0'),
      CurrencyAmount.fromRawAmount(USDB_BLAST_SEPOLIA, '0')
    ),
  ],
}

export function isMinerSupportedChain(chainId: number | undefined): boolean {
  return !!chainId && MinerSupportedChainId.some((chain) => chain == chainId)
}
